import React from 'react';
import styles from './styles.module.scss';
import { Description, Image } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import { BoardItem2 } from '../../../features/boarditem2/ui/BoardItem2';
import LeadShortSection from '../../../features/lead/shortSection/ui/LeadShortSection';

const Main9Block: React.FC<Main9BlockProps> = ({
    markup
}) => {


    return (
        <div className={styles.mainBlock9}>
            <div className={styles.mainBlock9__header}>
                <Image
                    className={styles.mainBlock9__baseImage}
                    src={parseCCSImage(markup?.$header?.$image?.value)}
                />
                <div className={styles.mainBlock9__textWrapper}>
                    <h1 className={styles.mainBlock9__title}>{markup?.$header?.$title?.value}</h1>    
                    <Description
                        className={styles.mainBlock9__description}
                        text={markup?.$header?.$description?.value}
                    />
                </div>
            </div>
            <LeadShortSection
                className={styles.mainBlock9__leadShortSection}
                title={markup?.$shortLeadSection?.$title?.value}
                inputLine1PlaceHolder={markup?.$shortLeadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$shortLeadSection?.$input2?.$placeholder?.value}
                confirmButtonLabel={markup?.$shortLeadSection?.$confirmButton?.$label?.value}
                pdfPath={parseCCSImage(markup?.$shortLeadSection?.$fileHyperLink?.$file?.value)}
                pdfLabel={markup?.$shortLeadSection?.$fileHyperLink?.$text?.value}
            />
            <div className={styles.mainBlock9__main}>
                {markup?.$navigationCardsList?.items?.map((navigationCard, index) => (
                    <BoardItem2
                        className={styles.mainBlock9__boardItem2}
                        index={index}
                        imageSrc1={parseCCSImage(navigationCard.$imagesList?.items?.at(0)?.value)}
                        imageSrc2={parseCCSImage(navigationCard.$imagesList?.items?.at(1)?.value)}
                        title={navigationCard.$title?.value}
                        description={navigationCard.$description?.value}
                        path ={navigationCard.$link?.value || ''}
                    />
                ))}
            </div>
        </div>
    )
}

export default Main9Block;