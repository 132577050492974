import styles from './styles.module.scss';
import cn from 'classnames';

import { Button, Description, Span, Text, Title } from "../../../shared"
import { Image } from "../../../shared/ui/image/Image"
import { Link } from 'react-router-dom';

const ArticleCard2: React.FC<ArticleCard2Props> = ({
    className,
    imageProps,
    nameLabelProps,
    descriptionProps,
    link
}, ...args) => {

    const __handlePurchaseButtonClick = () => {

    }

    const puchaseButtonProps: ButtonProps = {
        label: 'ЗАКАЗАТЬ ТОРТ',
        onClick: __handlePurchaseButtonClick
    }

    return (
        <div 
            className={cn(className, styles.articleCard2)}
            {...args}
        >
            <Link to={link || ''} >
                <div className={styles.articleCard2__imageWrapper}>
                    <Image
                        className={styles.articleCard2__image}
                        {...imageProps}
                    />
                </div>
            </Link>
            <Span
                className={styles.articleCard2__nameTitle}
                {...nameLabelProps}
                />
            <div className={styles.articleCard2__meta}>
                <div className={styles.articleCard2__divider}/>
                <Description
                    className={styles.articleCard2__description}
                    {...descriptionProps}
                />
            </div>
            <Button
                className={cn(styles.articleCard2__purchaseButton, styles.button_v3)}
                {...puchaseButtonProps}
            />
        </div>
    )
}

export default ArticleCard2;