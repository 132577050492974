import React, { useEffect, useRef, useState } from 'react';
import styles from './ImagesSlider.module.scss';
import cn from 'classnames';
import { Image } from '../../../shared/ui/image/Image';
import { Carousel } from 'antd';
import { Button } from '../../../shared';
import { Icon } from '../../../shared';

export type ImagesSliderProps = {
    className?: string
    imagesProps: ImageProps[]
}

export const ImagesSlider: React.FC<ImagesSliderProps> = ({
    className,
    imagesProps
}, ...args) => {

    const scrollRef = useRef<HTMLDivElement | HTMLImageElement | null>(null)
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        if (!!scrollRef.current) {
            const position = scrollRef.current?.scrollLeft;
            setScrollPosition(position);
        }
    };

    useEffect(() => {
        scrollRef.current?.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            scrollRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const [elementsList, setCurrentItem] = useState<HTMLDivElement>()


    const __onLeftClick = () => {
        scrollRef.current?.scrollTo({
            top: 0,
            left: scrollPosition - 500,
            behavior: 'smooth'
          });
        // if (currentIndex > 0 && !!scrollRef.current) {
        //     let nextIndex = currentIndex - 1
        //     console.log(nextIndex)
        //     // console.log(scrollRef.current.children[currentIndex].getBoundingClientRect().x - scrollRef.current.getBoundingClientRect().x)
        //     // console.log(scrollRef.current.children[currentIndex].getBoundingClientRect().left)
        //     // let postionLeft = Array.from(scrollRef.current.children)[currentIndex].scrollLeft

        //     // scrollRef.current.children[nextIndex].scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'})
        //     scrollRef.current.scrollTo({
        //         top: 0,
        //         left: scrollPosition - 500,
        //         behavior: 'smooth'
        //       });
        //     setCurrentIndex(nextIndex)
        // }
        // // if (!!scrollRef?.current) {            
        // //     scrollRef.current.scrollTo({
        // //         top: 0,
        // //         left: scrollRef.current.scrollLeft - 500,
        // //         behavior: 'smooth'
        // //       });
        // // }
    }

    // useEffect(() => {
    //     if (!!scrollRef.current?.children[0]) {
    //         setCurrentItem(scrollRef.current.children[0])
    //     }
    // }, [scrollRef.current])

    // useEffect(() => {
    //     console.log(scrollRef.current?.scrollLeft)
    // }, [currentItem?.scrollLeft])

    const __onRightClick = () => {
        scrollRef.current?.scrollTo({
            top: 0,
            left: scrollPosition + 500,
            behavior: 'smooth'
          });
        // if (currentIndex < imagesProps.length - 1 && !!scrollRef.current) {
        //     let nextIndex = currentIndex + 1
        //     console.log(nextIndex)
        //     // let postionLeft = scrollRef.current.children[nextIndex].scrollLeft
        //     // console.log(postionLeft)
        //     scrollRef.current.children[nextIndex].scrollIntoView({block: 'nearest', inline: 'center', behavior: 'smooth'})
        //     // scrollRef.current.scrollTo({
        //     //     top: 0,
        //     //     left: scrollRef.current.children[nextIndex].getBoundingClientRect().x - scrollRef.current.getBoundingClientRect().x,
        //     //     behavior: 'smooth'
        //     //   });
        //     setCurrentIndex(nextIndex)
        // }
        // if (!!scrollRef?.current) {            
        //     scrollRef.current.scrollTo({
        //         top: 0,
        //         left: scrollRef.current.scrollLeft + 500,
        //         behavior: 'smooth'
        //       });
        // }
    }
    return (
        <div
            className={cn(className, styles.imagesSlider)}
            {...args}
        >
            <div className={styles.imagesSlider__leftButtonWrapper}>
                <button
                    className={cn(styles.imagesSlider__leftButton, styles.imagesSlider__leftButton_m1)}
                    onClick={() => __onLeftClick()}
                >
                    <Icon 
                        className={styles.iconButton__icon_m1}
                        iconUID='leftarrow'
                    />
                </button>
            </div>
            <div className={styles.imagesSlider__content} ref={scrollRef}>
                {imagesProps.map((it, index) => (
                    <div className={styles.imagesSlider__imageWrapper}>
                        <Image
                            className={cn(styles.imagesSlider__image, styles.imagesSlider__image_m1)}
                            src={it.src}
                            key={index}
                            // ref={index === currentIndex ? scrollRef : {}}
                        />
                    </div>
                ))}
            </div>
            <div className={styles.imagesSlider__rightButtonWrapper}>
                <button
                    className={cn(styles.imagesSlider__rightButton, styles.imagesSlider__rightButton_m1)}
                    onClick={() => __onRightClick()}
                >
                    <Icon 
                        className={styles.iconButton__icon_m1}
                        iconUID='rightarrow'
                    />
                </button>
            </div>
        </div>
    )
}