import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { IconSpanLink, Title } from '../../../shared';

const NavigationContainerColumn: React.FC<NavigationContainerColumnProps> = ({
    className,
    titleProps,
    iconSpanLinks
}) => {
    return (
        <div className={cn(className, styles.navigationContainerColumn)}>
            <IconSpanLink
                className={styles.navigationContainerColumn__title}
                {...titleProps}
            />
            <ul className={styles.navigationContainerColumn__list}>
                {iconSpanLinks?.map(iconSpanLink => (
                    <IconSpanLink
                        className={styles.navigationContainerColumn__listItem}
                        {...iconSpanLink}
                    />
                ))}
            </ul>
        </div>
    )
}

const NavigationContainer: React.FC<NavigationContainerProps> = ({
    className,
    columns,
    titleProps,
    navigationContainerColumnsProps
}) => {
    return (
        <div className={cn(className, styles.navigationContainer)}>
            <IconSpanLink
                className={styles.navigationContainer__title}
                {...titleProps}
            />
            <div
                className={styles.navigationContainer__main}
                style={!!columns ? {
                    display: "grid",
                    gridTemplateColumns: `repeat(${columns}, 1fr)`
                } : {
                    display: "flex",
                    flexWrap: "wrap"
                }}
            >
                {navigationContainerColumnsProps?.map(navigationContainerColumnProps => (
                    <NavigationContainerColumn
                        className={styles.navigationContainer__column}
                        {...navigationContainerColumnProps}
                    />
                ))}
            </div>
        </div>
    )
}

const NavigationSection: React.FC<NavigationSectionProps> = ({
    className,
    navigationContainersProps
}) => {
    return (
        <div className={cn(className, styles.navigationSection)}>
            {navigationContainersProps?.map(navigationContainerProps => (
                <NavigationContainer
                    className={styles.navigationSection__container}
                    {...navigationContainerProps}
                />
            ))}
        </div>
    )
}

export default NavigationSection;