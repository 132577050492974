import { createAsyncThunk } from "@reduxjs/toolkit"
import CCSRequestFilter from "../../core/ccs/lib/CCSRequestFilter"
import CCSRequestTypes from "../../core/ccs/lib/CCSRequestTypes"
import fetchCCS from "../../core/ccs/api/fetchCCS"

type Params = {
    collectionName: string
    offset?: number,
    filters: CCSRequestFilter[]
    type: CCSRequestTypes
}

export const fetchCollection = createAsyncThunk("fetchCollection", async (params: Params) => {
    return await fetchCCS(
        params.collectionName, 
        [
            `populate=deep`,
            `pagination[start]=${params.offset ? params.offset : 0}`,
            `pagination[limit]=${process.env.REACT_APP_CCS_SHOP_PAGINATION_LIMIT}`
        ],
        params.filters.filter(it => it.value)
    )
})