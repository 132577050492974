import fetchCCS from "../../../core/ccs/api/fetchCCS"
import CCSResponseWrapper from "../../../core/ccs/lib/CCSResponseWrapper"
import { CCSError } from "../../../shared"
import CCREST from "../../../shared/api/CCREST"
import CCError from "../../../shared/lib/CCError"
import CCPageDataStore from "../../../shared/model/CCPageDataStore"
import RCDataStore from "../../../shared/model/RCDataStore"

/**
 * 
 * @param {number} pageId Уникальный идетификатор страницы
 * @returns Возвращает список объектов, в которых находятся данные для страницы
 */
export const createLoader = async (route: CCRoute) => {
    const dataStore = new CCPageDataStore(
        route.pageId,
        route.hasHeader, 
        route.hasFooter, 
        route.hasSidebar
    )
    await dataStore.initialize()
    // const pageDto = new CCREST().fetch(`pages/${pageId}`, ['populate=deep,7'], [])
    // const footerBlock1Data = fetchCCS(`footer-block1`, ['populate=deep'], [])
    // const footerBlock2Data = fetchCCS(`footer-block2`, ['populate=deep'], [])
    // const footerBlock3Data = fetchCCS(`footer-block3`, ['populate=deep'], [])
    // const footerBlock4Data = fetchCCS(`footer-block4`, ['populate=deep'], [])
    // const footerBlock5Data = fetchCCS(`footer-block5`, ['populate=deep'], [])
    // const footerBlock6Data = fetchCCS(`footer-block6`, ['populate=deep'], [])
    // const footerBlock7Data = fetchCCS(`footer-block7`, ['populate=deep'], [])
    // const footerBlock8Data = fetchCCS(`footer-block8`, ['populate=deep'], [])

    // const results = await Promise.all([
    //     dataStore,
    //     // footerBlock1Data,
    //     // footerBlock2Data,
    //     // footerBlock3Data,
    //     // footerBlock4Data,
    //     // footerBlock5Data,
    //     // footerBlock6Data,
    //     // footerBlock7Data,
    //     // footerBlock8Data
    // ])
    // console.log('P2', results)

    // console.log(new RCDataStore().parseResponse(results[0]))
    
    return {
        dataStore: dataStore
    };
}