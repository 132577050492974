import CCError from "../lib/CCError"

class CCREST {
    static instance: CCREST;

    constructor() {
        if (CCREST.instance) {
            return CCREST.instance;
          }
          CCREST.instance = this;
        
          // Можно заморозить экземпляр: холодный как лед, надежный как скала
          Object.freeze(this);
    }

    buildFilters = (filters: CCRequestFilter[]) => {
        return filters.filter(it => !!it.value).map(it => `filters${it.keys.map(key => `[${key}]`).join('')}[$contains]=${it.value}`)
    }

    buildRequestUrl = (
        path: string,
        params: string[],
        filters?: string,
        id?: number
    ) => {
        let parsedFilters: string[] = !!filters ? [filters] : []
        return `${process.env.REACT_APP_CCS_API_URL}${process.env.REACT_APP_CCS_API_PATH}` + '/' + path + (!!id ? `/${id}` : '') + '?' + params.concat(parsedFilters).join('&') 
    }

    fetch = async (
        entityName: string,
        params: string[],
        filters?: string,
        entityId?: number,
    ) => {
        try {
            const response = await fetch(this.buildRequestUrl(entityName, params, filters, entityId))
    
            if (response.status === 200) {
                const dto = await response.json()
                return {
                    status: response.status,
                    body: dto
                } as CCResponseWrapper
            } else {
                return new CCError(response.status, response.statusText)
            }
        } catch (e: any) {
            return new CCError(409, e.toString())
        }
    }
}

export default CCREST;