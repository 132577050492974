import styles from "./ShopFilter.module.scss"
import cn from "classnames"

const ShopFilter: React.FC<ShopFilterProps> = (props) => {

    const handleFilterClick = (value: any) => {
        props.setSelectedFilter(value)
    }

    return (
        <div className={cn(props.className, styles.shopFilter)}>
            <h1>{props.title}</h1>
            {props.filters.map(section => (
                <ul className={styles.shopFilter__section}>
                    {section.map(filter => (
                        <li
                            className={props.selectedFilter === filter.key ? styles.section__filter_active : styles.section__filter}
                            onClick={() => handleFilterClick(filter.key)}>
                                <span className={styles.filter__text}>{filter.text}</span>
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    )
}

export default ShopFilter