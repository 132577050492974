import React, { useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Button, Description, Tabs, Title } from '../../../shared';
import { AlertCard, ArticleElementsPicker, ImagesSlider, MetaCardsList, PortionPicker, RecommendationContainer, ShortLeadSection} from '../../../features';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';

const Main7Block: React.FC<Main7BlockProps> = ({
    markup
}, ...args) => {

    const [selectedPortionIndex, setSelectedPortionIndex] = useState<any>(0)

    const [selectedIndexes, setSelectedIndexes] = useState<any[]>([])

    const [leadInput1Value, setLeadInput1Value] = useState('')
    const [leadInput2Value, setLeadInput2Value] = useState('')

    const __onArticleElementClick = (key: any) => {
        if (selectedIndexes.includes(key)) {
            setSelectedIndexes(oldArray => [...oldArray.filter(it => it !== key)])
        } else {
            setSelectedIndexes(oldArray => [key, ...oldArray])
        }
    }

    const __onPortionPickerChange = (key: any) => {
        setSelectedPortionIndex(key)
    }

    const __onElementsTabsChange = (e: any) => {
        setSelectedElementsTab(e)
    }

    const [selectedElementsTab, setSelectedElementsTab] = useState(0)

    return (
        <div className={styles.mainBlock7} {...args}>
            <div className={styles.mainBlock7__articleSection}>
                <div
                    className={styles.mainBlock7__heading}
                >
                    <Title
                        className={cn(styles.mainBlock7__title, styles.mainBlock7__title_m1)}
                        value={markup.$title?.value}
                    />
                    <Button
                        className={cn(styles.mainBlock7__infoButton, styles.mainBlock7__infoButton_m1)}
                        label={markup.$infoButton?.$label?.value}
                        iconUID={markup.$infoButton?.$icon?.value}
                    />
                </div>
                <div className={styles.mainBlock7__imagesSliderWrapper}>
                    <ImagesSlider
                        className={styles.mainBlock7__imagesSlider}
                        imagesProps={markup.$imagesSlider?.$imagesList?.items?.map((image, index) => ({
                            src: parseCCSImage(image.value)
                        })) || []}
                    />
                </div>
                <div className={styles.mainBlock7__portionContainer}>
                    <div className={styles.mainBlock7__portionInfoSection}>
                        <Description
                            className={styles.mainBlock7__description}
                            text={markup.$description?.value}
                        />
                        <Button
                            className={cn(styles.mainBlock7__purchaseButton, styles.mainBlock7__purchaseButton_m1)}
                            label={markup.$purchaseButton?.$label?.value}
                            iconUID={markup.$purchaseButton?.$icon?.value}
                        />
                    </div>
                    <PortionPicker
                        className={styles.mainBlock7__portionPicker}
                        portionsCardsProps={markup.$portionPicker?.items?.map(portionCard => {
                            return {
                                count: portionCard.$volumeLabel.value,
                                description: portionCard.$description?.value,
                                weight: portionCard.$weightLabel?.value,
                                price: portionCard.$priceLabel?.value,
                            }
                        }) || []}
                        selectedIndex={selectedPortionIndex}
                        onChange={__onPortionPickerChange}
                    />
                </div>
                <Tabs
                    className={styles.mainBlock7__articleElementsTabs}
                    titleProps={{
                        value: markup.$articleElementsTabs?.$title.value
                    }}
                    items={markup.$articleElementsTabs?.$tabItemsList.items?.map((articleElementsTab, index) => ({
                        label: articleElementsTab.$label?.value
                    })) || []}
                    selected={selectedElementsTab}
                    onChange={__onElementsTabsChange}
                >
                    <ArticleElementsPicker
                        className={styles.mainBlock7__articleElementsPickerProps}
                        items={markup.$articleElementsPickersList?.items?.at(selectedElementsTab)?.$articleElementCardsList?.items?.map((articleElementCard, index) => ({
                            name: articleElementCard.$nameLabel?.value || '',
                            value: articleElementCard.$valueLabel?.value,
                            key: selectedElementsTab + '_' + index
                        })) || []}
                        selectedIndexes={selectedIndexes}
                        onChange={__onArticleElementClick}
                    />
                </Tabs>
                <div className={styles.mainBlock7__serviceIncludesContainer}>
                    <Title
                        className={styles.mainBlock7__serviceIncludesTitle}
                        value={markup.$serviceIncludesTitle?.value}
                    />
                    <MetaCardsList
                        className={styles.mainBlock7__serviceIncludesCards}
                        items={markup.$serviceIncludesCardsList?.items?.map((serviceIncludesCard, index) => ({
                            iconProps: {
                                iconUID: serviceIncludesCard.$icon?.value
                            },
                            titleProps: {
                                value: serviceIncludesCard.$title?.value
                            },
                            descriptionProps: {
                                text: serviceIncludesCard.$description?.value
                            }
                        })) || []}
                    />
                </div>
                <AlertCard
                    className={styles.mainBlock7__alertCard}
                    iconProps={{
                        iconUID: markup.$alertCard?.$icon?.value
                    }}
                    titleProps={{
                        value: markup.$alertCard?.$title?.value
                    }}
                    descriptionProps={{
                        text: markup.$alertCard?.$description?.value
                    }}
                />
            </div>
            <ShortLeadSection
                className={styles.mainBlock7__leadSection}
                titleProps={{
                    value: markup.$shortleadSection?.$title?.value
                }}
                input1Props={{
                    placeholder: markup.$shortleadSection?.$input1?.$placeholder?.value,
                    value: leadInput1Value,
                    setValue: setLeadInput1Value
                }}
                input2Props={{
                    placeholder: markup.$shortleadSection?.$input2?.$placeholder?.value,
                    value: leadInput2Value,
                    setValue: setLeadInput2Value
                }}
                confirmButtonProps={{
                    label: markup.$shortleadSection?.$confirmButton?.$label?.value,
                    iconUID: markup.$shortleadSection?.$confirmButton?.$icon?.value,
                }}
                fileHyperLinkProps={{
                    href: parseCCSImage(markup.$shortleadSection?.$fileHyperLink?.$file?.value),
                    text: markup.$shortleadSection?.$fileHyperLink?.$text?.value
                }}
            />
            <RecommendationContainer
                className={styles.mainBlock7__recommendationContainer}
                titleProps={{
                    value: markup.$recommendationsSection?.$title?.value
                }}
                articlesProps={markup.$recommendationsSection?.$articleCardsList?.items?.map((articleCard) => {
                    return {
                        weight: articleCard.$weightLabel?.value,
                        price: articleCard.$priceLabel?.value,
                        name: articleCard.$title?.value,
                        imageSrc: parseCCSImage(articleCard.$image?.value),
                        link: articleCard.$link?.value
                    }
                }) || []}
            />
        </div>
    )
}

export default Main7Block;