import React, { useState } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { IconSpanLink, Image, Tabs, Title } from '../../../shared';
import { Link } from 'react-router-dom';

const PortfolioEventsSliderCollage: React.FC<PortfolioEventsSliderCollageProps> = ({
    className,
    portfolioEventCard
}) => {
    return (
        <div className={cn(className, styles.portfolioEventsSliderFullBoard)}>
            <div className={styles.portfolioEventsSliderFullBoard__column1}>
                <Link 
                    className={styles.portfolioEventsSliderFullBoard__imageWrapper}
                    to={portfolioEventCard?.link || ''}
                >
                    <Image
                        className={styles.portfolioEventsSliderFullBoard__image}
                        {...portfolioEventCard?.imagesProps?.at(0)}
                    />
                </Link>
            </div>
            <div className={styles.portfolioEventsSliderFullBoard__column}>
                <Link
                    className={styles.portfolioEventsSliderFullBoard__imageWrapper}
                    to={portfolioEventCard?.link || ''}
                >
                    <Image
                        className={styles.portfolioEventsSliderFullBoard__image}
                        {...portfolioEventCard?.imagesProps?.at(1)}
                    />
                </Link>
                <Link
                    className={styles.portfolioEventsSliderFullBoard__imageWrapper}
                    to={portfolioEventCard?.link || ''}
                >
                    <Image
                        className={styles.portfolioEventsSliderFullBoard__image}
                        {...portfolioEventCard?.imagesProps?.at(2)}
                    />
                </Link>
            </div>
        </div>
    )
}

const PortfolioEventsSliderFullBoard: React.FC<PortfolioEventsSliderFullBoardProps> = ({
    className,
    portfolioEventCardsProps
}) => {
    return (
        <div className={cn(className, styles.portfolioEventsSliderFullBoard)}>
            <div className={styles.portfolioEventsSliderFullBoard__column1}>
                <Link 
                    className={styles.portfolioEventsSliderFullBoard__imageWrapper}
                    to={portfolioEventCardsProps?.at(0)?.link || ''}
                >
                    <Image
                        className={styles.portfolioEventsSliderFullBoard__image}
                        {...portfolioEventCardsProps?.at(0)?.imagesProps?.at(0)}
                    />
                </Link>
            </div>
            <div className={styles.portfolioEventsSliderFullBoard__column}>
                <Link 
                    className={styles.portfolioEventsSliderFullBoard__imageWrapper}
                    to={portfolioEventCardsProps?.at(1)?.link || ''}
                >
                    <Image
                        className={styles.portfolioEventsSliderFullBoard__image}
                        {...portfolioEventCardsProps?.at(1)?.imagesProps?.at(0)}
                    />
                </Link>
                <Link 
                    className={styles.portfolioEventsSliderFullBoard__imageWrapper}
                    to={portfolioEventCardsProps?.at(2)?.link || ''}
                >
                    <Image
                        className={styles.portfolioEventsSliderFullBoard__image}
                        {...portfolioEventCardsProps?.at(2)?.imagesProps?.at(0)}
                    />
                </Link>
            </div>
        </div>
    )
}

const PortfolioEventsSlider: React.FC<PortfolioEventsSliderProps> = ({
    className,
    asideImageProps,
    titleProps,
    portfolioEventCardsProps,
    hyperLinkProps
}) => {
    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <div className={cn(className, styles.portfolioEventsSlider)}>
            <div className={styles.portfolioEventsSlider__asideImageWrapper}>
                <Image
                    className={styles.portfolioEventsSlider__asideImage}
                    {...asideImageProps}
                />
            </div>
            <div className={styles.portfolioEventsSlider__main}>
                <Title
                    className={styles.portfolioEventsSlider__title}
                    {...titleProps}
                />
                <Tabs
                    className={styles.portfolioEventsSlider__tabs}
                    tabItemClassName={styles.portfolioEventsSlider__tabItem}
                    items={[{
                        label: 'Все',
                        key: 0
                    }].concat(
                        portfolioEventCardsProps?.map((portfolioEventCard, index) => {
                            return {
                                label: String(portfolioEventCard.titleProps?.value),
                                key: index + 1
                            }
                        }) || []
                    )
                    }
                    selected={selectedTab}
                    onChange={(e) => setSelectedTab(e)}
                />
                {selectedTab !== 0 ? (
                    <PortfolioEventsSliderCollage
                        className={styles.portfolioEventsSlider__collage}
                        portfolioEventCard={portfolioEventCardsProps?.at(selectedTab - 1)}
                    />
                ) : (
                    <PortfolioEventsSliderFullBoard
                        className={styles.portfolioEventsSlider__collage}
                        portfolioEventCardsProps={portfolioEventCardsProps}
                    />
                )}
                <IconSpanLink
                    className={styles.portfolioEventsSlider__hyperLink}
                    {...hyperLinkProps}
                />
            </div>
        </div>
    )
}

export default PortfolioEventsSlider;