import React, { useState } from 'react'
import styles from './LeadShortSection.module.scss';
import cn from 'classnames'
import { InputLine, Button } from "../../../../shared"

type Props = {
    className?: string
    title?: string,
    confirmButtonLabel?: string,
    inputLine1PlaceHolder?: string,
    inputLine2PlaceHolder?: string,
    pdfLabel?: string
    pdfPath?: string
}

const LeadShortSection: React.FC<Props> = (props) => {

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const handleConfirmButtonClick = () => {
        
    }

    return (
        <div className={cn(props.className, styles.leadShortSection)}>
            <h1 className={styles.leadShortSection__title}>{props.title}</h1>
            <div className={styles.leadShortSection__form}>
                <InputLine
                    className={styles.leadShortSection__nameInputLine}
                    type="text"
                    value={name}
                    placeholder={props.inputLine1PlaceHolder}
                    setValue={setName}/>
                <InputLine
                    className={styles.leadShortSection__phoneNumberInputLine}
                    type="text"
                    value={phoneNumber}
                    placeholder={props.inputLine2PlaceHolder}
                    setValue={setPhoneNumber}/>
                <Button
                    className={cn(styles.leadShortSection__confirmButton, styles.button_v4)}
                    label={!!props.confirmButtonLabel ? props.confirmButtonLabel : ''}
                    onClick={handleConfirmButtonClick}
                />
            </div>
            <div className={styles.leadShortSection__divider}>
                <div/>
                <span>{'или'}</span>
                <div/>
            </div>
            <a
                className={styles.leadShortSection__pdfLink}
                href={props.pdfPath}>
                    {props.pdfLabel}
            </a>
        </div>
    )
}

export default LeadShortSection;