import React from 'react';
import styles from './BoardItem2.module.scss';
import cn from 'classnames';
import { Description, Image } from '../../../shared';
import { Link } from 'react-router-dom';

type Props = {
    className?: string,
    index: number
    imageSrc1?: string,
    imageSrc2?: string,
    title?: string,
    description?: string,
    path: string
}

export const BoardItem2: React.FC<Props> = (props) => {
    return (
        <Link to={props.path} key={props.index}>
            <div className={cn(props.className, styles.boardItem2)}>
                {props.index % 2 === 0 ? (
                    <div className={styles.boardItem2__imagesWrapper_v1}>
                        <div>
                            <Image
                                className={styles.boardItem2__image1_v1}
                                src={props.imageSrc1}
                            />
                        </div>
                        <div>
                            <Image
                                className={styles.boardItem2__image2_v1}
                                src={props.imageSrc2}
                            />
                        </div>
                    </div>
                ): (
                    <div className={styles.boardItem2__imagesWrapper_v2}>
                        <div>
                            <Image
                                className={styles.boardItem2__image1_v2}
                                src={props.imageSrc1}
                            />
                        </div>
                        <div>
                            <Image
                                className={styles.boardItem2__image2_v2}
                                src={props.imageSrc2}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.boardItem2__textContainer}>
                    <h2 className={styles.boardItem2__title}>{props.title}</h2>
                    <Description
                        className={styles.boardItem2__description}
                        text={props.description}
                    />
                </div>
            </div>
        </Link>
    )
}