import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { NavigationSection } from '../../../features';
import { Button } from '../../../shared';
import { usePageDataStore } from '../../../shared/lib/usePageDataStore';

const Sidebar: React.FC<SidebarProps> = ({
    className,
    markup
}) => {
    const { pageDataStore } = usePageDataStore()


    return (
        <div className={cn(styles.sidebar, className)}>
            <div className={styles.sidebar__closeButtonWrapper}>
                <Button
                    className={styles.sidebar__closeButton}
                    iconUID='close'
                    onClick={() => pageDataStore?.changeSidebarVisibility()}
                    children={<>Закрыть</>}
                />
            </div>
            <NavigationSection
                className={styles.sidebar__navigationSection}
                navigationContainersProps={markup?.$navigationContainersList?.items?.map(navigationContainer => {
                    return {
                        columns: navigationContainer.columns,
                        titleProps: {
                            text: navigationContainer.$title?.$span?.value,
                            iconUID: navigationContainer.$title?.$icon?.value,
                            link: navigationContainer.$title?.$link?.value
                        },
                        navigationContainerColumnsProps: navigationContainer.$iconSpanLinksListList?.items?.map(iconSpanLinksList => {
                            return {
                                titleProps: {
                                    text: iconSpanLinksList.$title?.$span?.value,
                                    iconUID: iconSpanLinksList.$title?.$icon?.value,
                                    link: iconSpanLinksList.$title?.$link?.value,
                                },
                                iconSpanLinks:  iconSpanLinksList.$iconSpanLinksList.items?.map(iconSpanLink => {
                                    return {
                                        text: iconSpanLink.$span?.value,
                                        iconUID: iconSpanLink.$icon?.value,
                                        link: iconSpanLink.$link?.value
                                    }
                                })
                            }
                        }) || []
                    }
                }) || []}
            />
        </div>
    )
}

export default Sidebar;