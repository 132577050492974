import React, { ReactNode } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'
import styled from 'styled-components';
import Icon from '../Icon';

export const Button: React.FC<ButtonProps> = ({
    className,
    label,
    iconUID,
    style,
    onClick
}) => {
    return (
        <button
            className={cn(className, styles.button)}
            style={style}
            onClick={onClick}
        >
            <div>
                <Icon
                    className={styles.button__icon}
                    iconUID={iconUID}
                />
                <span>{label}</span>
            </div>
        </button>
    )
}
