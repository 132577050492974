import { useState } from "react"
import styles from "./styles.module.scss"
import cn from "classnames"
import { Description, InputField, InputLine, Button } from "../../../../shared"

type Props = {
    className?: string
    title?: string
    description?: string
    confirmButtonLabel?: string
    inputLine1PlaceHolder?: string
    inputLine2PlaceHolder?: string
    inputLine3PlaceHolder?: string
    pdfLabel?: string
    pdfPath?: string
}

export const LeadForm: React.FC<Props> = (props) => {

    const [input1Value, setInput1Value] = useState("")
    const [input2Value, setInput2Value] = useState("")
    const [input3Value, setInput3Value] = useState("")

    const handleClick = () => {

    }

    return (
        <div className={cn(props.className, styles.leadForm)}>
            <h1 className={styles.leadForm__title}>{props.title}</h1>
            <Description
                className={styles.leadForm__description}
                text={props.description}
            />
            <InputLine
                className={styles.leadForm__input1}
                type="text"
                value={input1Value}
                placeholder={props.inputLine1PlaceHolder}
                setValue={setInput1Value}/>
            <InputLine
                className={styles.leadForm__input2}
                type="text"
                value={input2Value}
                placeholder={props.inputLine2PlaceHolder}
                setValue={setInput2Value}/>
            {!!props.inputLine3PlaceHolder ? (
                <InputField
                    className={styles.leadForm__input3}
                    value={input3Value}
                    placeholder={props.inputLine3PlaceHolder}
                    setValue={setInput3Value}/>
            ) : <></>}
            <Button
                className={styles.leadForm__acceptButton}
                onClick={handleClick}
                label={!!props.confirmButtonLabel ? props.confirmButtonLabel : ""}
            />
            <div className={styles.leadForm__divider}>
                <div/>
                <span>или</span>
                <div/>
            </div>
            <a className={styles.leadForm__pdfLink} href={props.pdfPath}>{props.pdfLabel}</a>
        </div>
    )
}