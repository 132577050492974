import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Button, Description, Image, Title } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import { Icon } from '../../../shared';
import { Link } from 'react-router-dom';
import { usePageDataStore } from '../../../shared/lib/usePageDataStore';

const HomeBlock: React.FC<HomeBlockProps> = ({
    markup
}) => {
    const { pageDataStore } = usePageDataStore()

    const handleMenuClick = () => {
        pageDataStore?.changeSidebarVisibility()
    }

    return (
        <div className={styles.mainBlock0}>
            <div className={styles.mainBlock0__sidebar}>
                {markup?.$contactsList?.items?.map((contact) => (
                    <div className={styles.mainBlock0__address}>
                        <Icon iconUID={contact.$icon?.value}/>
                        <span>{contact.$span?.value}</span>
                    </div>
                ))}

                <Image
                    className={styles.mainBlock0__logoImage}
                    src={parseCCSImage(markup?.$logo?.value)}
                    blurhash={markup?.$logo?.value?.data.attributes.blurhash}
                />
                <ul className={styles.mainBlock0__navigationTabs}>
                    <li>
                        <Button
                            className={cn(styles.mainBlock0__menuButton, styles.button_v1)}
                            style={{background: 'black'}}
                            onClick={handleMenuClick}
                            iconUID={markup?.$menuButton?.$icon?.value}
                            label={markup?.$menuButton?.$label?.value}/>
                    </li>
                    {markup?.$navigation?.items?.map((it, index) => (
                        <li key={index}>
                            <Link to={it?.$link?.value || ''}>
                                <span>{it.$span?.value}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.mainBlock0__main}>
                <div className={styles.mainBlock0__header}>
                    <Image
                        className={styles.mainBlock0__headerImage} 
                        src={markup?.$header?.$image?.value && parseCCSImage(markup.$header?.$image?.value)}
                        blurhash={markup?.$header?.$image?.value?.data?.attributes?.blurhash}
                    />
                    <div className={styles.mainBlock0__headerTextWrapper}>
                        <Title
                            className={styles.mainBlock0__title}
                            value={markup?.$header?.$title?.value}
                        />
                        <Description
                            className={styles.mainBlock0__description}
                            text={markup?.$header?.$description?.value}
                        />
                    </div>
                </div>
                <div className={styles.mainBlock0__navigationContainer}>
                    {markup?.$popularContainer?.items?.map((popularCard, index) => (
                        <div className={styles.mainBlock0__section} key={index}>
                            <h2 className={styles.mainBlock0__sectionTitle}>{popularCard?.$title?.value || ''}</h2>
                            <div className={styles.mainBlock0__horizontalDivider}/>
                            <Image
                                className={styles.mainBlock0__sectionImage}
                                src={parseCCSImage(popularCard?.$image?.value)}
                                blurhash={popularCard.$image?.value?.data.attributes.blurhash}
                            />
                            <div className={styles.mainBlock0__itemsSectionWrapper}>
                                <div className={styles.mainBlock0__sectionVerticalDivider}/>
                                <div className={styles.mainBlock0__itemsSection}>
                                    {popularCard?.$elementsList?.items?.map((popularElement, index) => (
                                        <div className={styles.mainBlock0__itemsSectionItem}>
                                            <span className={styles.mainBlock0__sectionNumber}>{`${popularElement?.$numberLabel?.value}`?.padStart(2, "0")}</span>
                                            <h3 className={styles.mainBlock0__sectionTitle}>
                                                <Link to={popularElement?.$title?.$link?.value || ''}>{popularElement.$title?.$span?.value}</Link>
                                            </h3>
                                            <Description className={styles.mainBlock0__sectionDescription} text={popularElement.$description?.value}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomeBlock;

{/* <React.Suspense fallback={<></>}>
<Await
    resolve={pageData}
> */}