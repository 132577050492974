import React, { useState } from "react"
import styles from "./styles.module.scss"
import cn from "classnames"
import { AsideTitle, Description, Image, InputLine, Button } from "../../../../shared"

type Props = {
    className?: string
    title?: string,
    description?: string
    asideTitleSectionTitle?: string
    asideTitleSectionSubtitle?: string
    baseImageSrc?: string
    confirmButtonLabel?: string,
    inputLine1PlaceHolder?: string,
    inputLine2PlaceHolder?: string,
    inputLine3PlaceHolder?: string,
    pdfLabel?: string
    pdfPath?: string
}

export const LeadSection: React.FC<Props> = (props) => {

    const [input1Value, setInput1Value] = useState("")
    const [input2Value, setInput2Value] = useState("")
    const [input3Value, setInput3Value] = useState("")

    const handleConfirmButtonClick = () => {
        
    }

    return (
        <div className={cn(props.className, styles.leadSection)}>
            <AsideTitle
                className={styles.leadSection__asideTitle}
                title={!!props.asideTitleSectionTitle ? props.asideTitleSectionTitle : ''}
                subtitle={props.asideTitleSectionSubtitle}/>
            <div className={styles.leadSection__imageWrapper}>
                <Image
                    className={styles.leadSection__image}
                    src={props.baseImageSrc}/>
            </div>
            <div className={styles.leadSection__leadForm}>
                <h1 className={styles.leadForm__title}>{props.title}</h1>
                <Description
                    className={styles.leadForm__subtitle}
                    text={props.description}
                />
                <InputLine
                    className={styles.leadForm__nameInputLine}
                    type="text"
                    value={input1Value}
                    placeholder={props.inputLine1PlaceHolder}
                    setValue={setInput1Value}/>
                <InputLine
                    className={styles.leadForm__phoneNumberInputLine}
                    type="text"
                    value={input2Value}
                    placeholder={props.inputLine2PlaceHolder}
                    setValue={setInput2Value}/>
                {!!props.inputLine3PlaceHolder ? (
                    <InputLine
                        className={styles.leadForm__phoneNumberInputLine}
                        type="text"
                        value={input3Value}
                        placeholder={props.inputLine2PlaceHolder}
                        setValue={setInput3Value}
                    />
                ) : <></>}
                <Button
                    className={styles.leadForm__acceptButton}
                    onClick={handleConfirmButtonClick}
                    label={!!props.confirmButtonLabel ? props.confirmButtonLabel : ""}
                />
                <div className={styles.leadForm__divider}>
                    <div/>
                    <span>или</span>
                    <div/>
                </div>
                <a className={styles.leadForm__pdfLink} href={props.pdfPath}>
                    {props.pdfLabel}
                </a>
            </div>
        </div>
    )
}