import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Tabs } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import { LeadSection } from '../../../features';
import { BoardItem2 } from '../../../features/boarditem2/ui/BoardItem2';

const Main5Block: React.FC<Main5BlockProps> = ({
    markup
}) => {
    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <div className={styles.mainBlock5}>
            <LeadSection
                className={styles.mainBlock5__leadSection}
                title={markup?.$leadSection?.$title?.value}
                inputLine1PlaceHolder={markup?.$leadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$leadSection?.$input2?.$placeholder?.value}
                confirmButtonLabel={markup?.$leadSection?.$confirmButton?.$label?.value}
                pdfPath={parseCCSImage(markup?.$leadSection?.$fileHyperLink?.$file?.value)}
                pdfLabel={markup?.$leadSection?.$fileHyperLink?.$text?.value}
            />
            <div className={styles.mainBlock5__header}>
                <h1>{markup?.$title?.value}</h1>
            </div>
            <Tabs
                className={styles.mainBlock5__navigationTabs}
                items={markup?.$tabs?.$tabItemsList.items?.map((tab, index) => {
                    return {
                        key: index,
                        label: tab.$label?.value
                    }
                }) || []}
                selected={selectedTab}
                onChange={(e) => setSelectedTab(e)}
            />
            <div className={styles.mainBlock5__main}>
                {markup?.$navigationCardsListsList?.items?.at(selectedTab)?.items?.map((navigationCard, index) => (
                    <BoardItem2
                        className={styles.mainBlock5__boardItem2}
                        index={index}
                        imageSrc1={parseCCSImage(navigationCard.$imagesList?.items?.at(0)?.value)}
                        imageSrc2={parseCCSImage(navigationCard.$imagesList?.items?.at(1)?.value)}
                        title={navigationCard.$title?.value}
                        description={navigationCard.$description?.value}
                        path={navigationCard.$link?.value || ''}
                    />
                ))}
            </div>
        </div>
    )
}

export default Main5Block;