import React, { useState } from 'react';
import styles from './PortionCard.module.scss';
import cn from 'classnames'
import { Description } from '../../../shared';

export type PortionCardProps = {
    className?: string
    isSelected: boolean
    count?: number
    description?: string
    weight?: number
    price?: number
    onClick?: () => any
}

export const PortionCard: React.FC<PortionCardProps> = ({
    className,
    isSelected,
    count,
    description,
    weight,
    price,
    onClick
}, ...args) => {
    /**
     * Обработчик нажатия на карточку
     */
    const __onCardClick = () => {
        if (!!onClick) onClick()
    }

    return (
        <div
            className={cn(className, styles.portionCard, isSelected ? styles.portionCard_selected : styles.portionCard_unselected)}
            onClick={__onCardClick}
        >
            <span className={styles.portionCard__count}>{count} ПОРЦИЙ</span>
            <Description className={styles.portionCard__description} text={description}/>
            <span className={styles.portionCard__weight}>Вес: {weight} г</span>
            <span className={styles.portionCard__price}>{price}р.</span>
        </div>
    )
}