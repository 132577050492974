import styles from './styles.module.scss';
import cn from 'classnames';
import Icons from '../../lib/theme/icons.json';
  
const Icon: React.FC<IconProps> = ({
  className,
  iconUID
}, ...restProps) => {

  let iconData = !!iconUID ? Object.entries(Icons)?.find(it => it[0] === iconUID)?.[1] : undefined
  return !!iconData ? (
    <svg 
      className={cn(className, styles.icon)}
      width={iconData.width} 
      height={iconData.height} 
      viewBox={`0 0 ${iconData.width} ${iconData.height}`} 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={iconData.path} 
        fill="currentColor"
      />
    </svg>
  ) : null
};

export default Icon;