import { AppStoreProvider } from "../../shared";
import CCAppDataStore from "../../shared/model/app/CCAppDataStore";

const appStore = new CCAppDataStore()

export const withAppStore = (component: () => React.ReactNode) => () => (
    <AppStoreProvider 
        appStore={appStore} 
        children={component()}
    />
)