import React, { useState } from "react"
import { Clicker, Image, Button } from "../../../../shared"
import { strings } from "../lib"
import styles from "./ShopItemCard.module.scss"
import cn from "classnames"
import Skeleton from '@mui/material/Skeleton';
import { Link } from "react-router-dom"

type Props = {
    className?: string
    id: number
    name: string
    price: number
    weight: number
    baseImageSrc?: string
    path?: string
    onPurchaseClick: () => void
    pending?: boolean
}

const ShopItemCard: React.FC<Props> = ({
    className,
    name,
    price,
    weight,
    baseImageSrc,
    path,
    onPurchaseClick,
    pending
}) => {
    const [value, setValue] = useState(1)

    const handlePurchaseClick = () => {
        onPurchaseClick()
    }

    return !pending ? (
        <div className={cn(className, styles.shopitemcard)}>
            <Link to={path ? path : ''}>
                <div className={styles.shopitemcard__main}>
                    <Image
                        className={styles.shopitemcard__image}
                        src={baseImageSrc}
                    />
                    <div className={styles.shopitemcard__textwrapper}>
                        <span className={styles.shopitemcard__weightlabel}>{strings.weightPreTitle}{weight}{strings.weightPostTitle}</span>
                        <span className={styles.shopitemcard__pricelabel}>{new Intl.NumberFormat().format(price)}{strings.priceValute}</span>
                    </div>
                </div>
                <div className={styles.shopitemcard__titlewrapper}>
                    <span className={styles.shopitemcard__title}>{name}</span>
                </div>
            </Link>
            <div className={styles.shopitemcard__footer}>
                <Clicker
                    className={styles.shopitemcard__clicker}
                    value={value}
                    setValue={setValue}/>
                <div className={styles.shopitemcard__spacer}/>
                <Button
                    className={cn(styles.shopitemcard__purchasebutton, styles.button_v2)}
                    onClick={handlePurchaseClick}
                    iconUID="basket"
                    label={strings.purchaseButtonText}
                />
            </div>
        </div>
    ) : (
        <PendingShopItemCard className={className}/>
    )
}

type PendingProps = {
    className?: string
}

const PendingShopItemCard: React.FC<PendingProps> = ({
    className
}) => {
    return (
        <div className={cn(className, styles.pendingshopitemcard)}>
            <div className={styles.pendingshopitemcard__main}>
                <Skeleton className={styles.pendingshopitemcard__image} variant="rectangular" width="100%"><div/></Skeleton>
                <div className={styles.pendingshopitemcard__textwrapper}>
                    <Skeleton className={styles.pendingshopitemcard__weightlabel}/>
                    <Skeleton className={styles.pendingshopitemcard__pricelabel}/>
                </div>
            </div>
            <Skeleton className={styles.pendingshopitemcard__title}/>
            <div className={styles.pendingshopitemcard__footer}>
                <Skeleton className={styles.pendingshopitemcard__clicker}/>
                <div className={styles.pendingshopitemcard__spacer}/>
                <Skeleton className={styles.pendingshopitemcard__purchasebutton}/>
            </div>
        </div>
    )
}

export default ShopItemCard