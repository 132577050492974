import cn from 'classnames';
import styles from './styles.module.scss';
import { baseTheme } from '../../styles/theme';

export const Title: React.FC<TitleProps> = ({
    className,
    value
}, ...args) => {
    return (
        <h1 className={cn(className, styles.title)} {...args}>
            {value}
        </h1>
    )
}