import PageControl from "../../../pages"
import { createLoader } from "./create-loader"

/**
 * 
 * @param pages Список страниц
 * @returns Возвращает список роутов для react-router-dom
 */
export const createRoutes = (routes: CCRoute[]) => {
    return routes.map((route, index) => {
        return {
            path: route.path,
            loader: async() => createLoader(route),
            element: <PageControl/>,
        }
    }) 
}