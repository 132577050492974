import React from "react";
import styles from './styles.module.scss';
import cn from 'classnames';
import { Link } from "react-router-dom";
import Span from "../Span";
import Icon from "../Icon";

const IconSpanLink: React.FC<IconSpanLinkProps> = ({
    className,
    iconUID,
    text,
    link
}) => {
    return (
        <Link
            className={cn(className, styles.iconSpanLink)}
            to={link || ''}
        >
            <Icon
                className={styles.iconSpanLink__icon}
                iconUID={iconUID}
            />
            <Span
                className={styles.iconSpanLink__span}
                value={text || ''}
            />
        </Link>
    )
}

export default IconSpanLink;