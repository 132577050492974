import React from "react"
import styles from "./InputLine.module.scss"
import cn from "classnames"

export type InputLineProps = {
    className?: string,
    value: string
    setValue: (value: string) => void
    placeholder?: string
    type?: string
}

export const InputLine: React.FC<InputLineProps> = ({
    className,
    setValue,
    placeholder
}, ...args) => {

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }
    return (
        <input
            className={cn(className, styles.inputLine)}
            {...args}
            placeholder={placeholder}
            onChange={handleChanges}/>
    )
}