import React from 'react';
import styles from './ArticleElement.module.scss';
import cn from 'classnames';

export type ArticleElementProps = {
    className?: string;
    key?: any
    name: string;
    value?: string;
    selected?: boolean;
    onClick?: (e: any) => any
}

export const ArticleElement: React.FC<ArticleElementProps> = ({
    className,
    name,
    value,
    selected,
    onClick
}, ...args) => {

    const __onClick = (e: any) => {
        if (onClick) onClick(e)
    }

    return (
        <div
            className={cn(
                className, 
                styles.articleElement,
                selected ? styles.articleElement_selected : styles.articleElement_unselected
            )}
            {...args}
            onClick={__onClick}
        >
            <span className={styles.articleElement__nameText}>
                {name}
            </span>
            <span className={styles.articleElement__valueText}>
                {value}
            </span>
        </div>
    )
}