import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { IconSpanLink } from '../../../shared';

const ShortNavigation: React.FC<ShortNavigationProps> = ({
    className,
    iconSpanLinksProps
}) => {
    return (
        <div className={cn(className, styles.shortNavigation)}>
            <ul className={styles.shortNavigation__list}>
                {iconSpanLinksProps?.map((iconSpanLinkProps, index) => (
                    <li key={index}>
                        <IconSpanLink
                            className={styles.shortNavigation__iconSpanLink}
                            {...iconSpanLinkProps}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ShortNavigation;