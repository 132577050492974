import React, { useContext } from "react"
import CCAppDataStore from "../model/app/CCAppDataStore"

const AppStoreContext = React.createContext({} as {
    appStore?: CCAppDataStore
})

type Props = {
    appStore: CCAppDataStore,
    children: React.ReactNode
}

export const AppStoreProvider: React.FC<Props> = ({
    appStore,
    children
}) => {
    const context = {
        appStore: appStore
    }
    return <AppStoreContext.Provider value={context}>{children}</AppStoreContext.Provider>
}

export const useAppStore = () => {
    const context = useContext(AppStoreContext);
    return context;
}