import React, { useEffect, useState } from 'react';
import styles from './Tabs.module.scss';
import cn from 'classnames';
import { Title } from '../title';


export const Tabs: React.FC<TabsProps> = ({
    className,
    tabItemClassName,
    titleProps,
    items,
    children,
    selected,
    onChange
}, ...args) => {
    const [isUpdated, setUpdated] = useState(false)

    const __onChange = (e: any) => {
        onChange(e)
    }

    useEffect(() => {
        setUpdated(true)
        setTimeout(() => {
            setUpdated(false)
        }, 500)
    }, [children])

    return (
        <div className={cn(className, styles.tabs)}>
            {!!titleProps ? (
                <Title
                    className={cn(styles.tabs__title, styles.tabs__title_m1)}
                    {...titleProps}
                />
            ) : <></>}
            <div className={styles.tabs__heading}>
                {items.map((tabItemProps, index) => (
                    <TabItem
                        className={cn(
                            styles.tabs__item,
                            tabItemClassName
                        )}
                        isSelected={index === selected}
                        key={index}
                        onClick={() => __onChange(index)}
                        {...tabItemProps}
                    />
                ))}
            </div>
            <div className={cn(
                styles.tabs__content,
                isUpdated ? styles.tabs__content_m1 : styles.tabs__content_m2
            )}>
                {children}
            </div>
        </div>
    )
}

export const TabItem: React.FC<TabItemProps> = ({
    className,
    label,
    isSelected,
    onClick
}, ...args) => {

    const __onClick = () => {
        if (onClick) onClick()
    }

    return (
        <div
            className={cn(
                className,
                styles.tabItem,
                isSelected ? styles.tabItem_selected : styles.tabItem_unselected,
            )}
            onClick={__onClick}
            {...args}
        >
            <span className={cn(styles.tabItem__label, styles.tabItem__label_m1)}>{label}</span>
        </div>
    )
}