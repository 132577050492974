import { Image } from "../image/Image"
import styles from "./styles.module.scss"
import cn from "classnames"

type Props = {
    className?: string
    imagesUrls?: string[]
    selected: any
    setSelected: (value: any) => void
}

export const ImagesHolder: React.FC<Props> = (props) => {

    return (
        <div className={cn(props.className, styles.imagesHolder)}>
            <Image
                className={styles.imagesHolder__baseImage}
                src={props.imagesUrls?.at(props.selected)}/>
            <div className={styles.imagesHolder__holder}>
                {props.imagesUrls?.map((value, index) => {
                    return (
                        <Image
                            className={cn(styles.imagesHolder__image, index === props.selected ? styles.image_selected : styles.image_unselected)}
                            src={value}
                            onClick={() => props.setSelected(index)}/>
                    )
                })}
            </div>
        </div>
    )
}