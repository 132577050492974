class CCError {
    code
    message

    constructor(code: number, message: string) {
        this.code = code
        this.message = message
    }
};

export default CCError;