import React, { useState } from 'react';
import styles from './MenusSection.module.scss';
import cn from 'classnames';
import VerticalTabs from '../../../shared/ui/verticaltabs/VerticalTabs';
import { Button, Description, Image } from '../../../shared';
import markup from '../lib/markup.json';
import CCSData from '../../../core/ccs/lib/CCSData';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import { Document, Page,pdfjs } from 'react-pdf'; 
import { useNavigate } from 'react-router-dom';

type Props = {
    className?: string;
    items?: CCSData[];
}

const MenusSection: React.FC<Props> = ({
    className,
    items
}) => {
    const [selectedMenu, setSelectedMenu] = useState(0)

    pdfjs.GlobalWorkerOptions.workerSrc =  
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 

    return (
        <div className={cn(className, styles.menusSection)}>
            <VerticalTabs
                className={cn(styles.menusSection__verticalTabs, styles.verticalTabs_v1)}
                selected={selectedMenu}
                items={!!items ? items.map((it, index) => (
                    {
                        key: index,
                        value: `МЕНЮ №${it.id}`
                    }
                )) : []}  
                onChange={(key: any) => setSelectedMenu(key)}  
            />
            <div className={styles.menusSection__main}>
                <Description 
                    className={cn(styles.menusSection__description, styles.description_v2)}
                    text={items?.at(selectedMenu)?.attributes?.description}
                />
                <Description 
                    className={cn(styles.menusSection__warningLabel, styles.description_v3)}
                    text={markup.main.warningLabel.prefix + items?.at(selectedMenu)?.attributes?.warning}
                />
                <Document
                    className={styles.menusSection__baseImage}
                    file={items?.at(selectedMenu)?.attributes?.file} 
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={() => {}} 
                    >
                    <Page 
                        className={styles.menusSection__documentPage}
                        pageNumber={1}/> 
                </Document> 
                <div className={styles.menusSection__buttonsSection}>
                    <Button
                        className={cn(styles.menusSection__downloadButton, styles.button_v5)}
                        iconUID=""
                        label={markup.main.buttonsSection.downloadButton.label}
                        onClick={() => window.open(items?.at(selectedMenu)?.attributes?.file)}
                    />
                    <Button
                        className={cn(styles.menusSection__checkButton, styles.button_v4)}
                        label={markup.main.buttonsSection.checkButton.label}
                        onClick={() => {}}
                    />
                </div>
            </div>
            <div className={styles.menusSection__aside}>
                <div/>
                <span className={styles.label_v1}>{markup.aside.label}</span>
            </div>
        </div>
    )
};

export default MenusSection;