import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { BoardCard3, FAQContainer, ImagesSlider, LeadForm, LeadSection, NavigationSection, PortfolioEventsSlider, ShortNavigation } from '../../../features';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import { AsideTitle, IconSpanLink, Image, Span, Title } from '../../../shared';
import CrewMembersSlider from '../../../features/crewMemberSlider/ui';
import { Map } from '@pbe/react-yandex-maps';

const Footer: React.FC<FooterProps> = ({
    className,
    markup
}) => {
    return (
        <div className={cn(className, styles.footer)}>
            <div className={styles.footer__awardsGrid}>
                {markup?.$awardsGrid?.$awardCardsList?.items?.map((awardCard, index) => (
                    <BoardCard3
                        className={styles.awardsGrid__card}
                        key={index}
                        baseImageSrc={parseCCSImage(awardCard.$image?.value)}
                        title={awardCard.$title?.value}
                        description={awardCard.$description?.value}
                    />
                ))}
            </div>


            <ImagesSlider
                className={styles.footer__imagesSlider}
                imagesProps={markup?.$imagesSlider?.$imagesList?.items?.map((image, index) => {
                    return {
                        src: parseCCSImage(image.value),
                        blurhash: image.value?.data.attributes.blurhash
                    }
                }) || []}
            />

            <LeadSection
                className={styles.footer__leadSection}
                title={markup?.$leadSection?.$title?.value}
                description={markup?.$leadSection?.$description?.value}
                asideTitleSectionTitle={markup?.$leadSection?.$asideMeta?.$title?.value}
                asideTitleSectionSubtitle={markup?.$leadSection?.$asideMeta?.$subtitle?.value}
                baseImageSrc={parseCCSImage(markup?.$leadSection?.$image?.value)}
                confirmButtonLabel={markup?.$leadSection?.$confirmButton?.$label?.value}
                inputLine1PlaceHolder={markup?.$leadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$leadSection?.$input2?.$placeholder?.value}
                pdfLabel={markup?.$leadSection?.$fileHyperLink?.$text?.value}
                pdfPath={parseCCSImage(markup?.$leadSection?.$fileHyperLink?.$file?.value)}
            />

            <CrewMembersSlider
                className={styles.footer__crewMembersSlider}
                titleProps={{
                    value: markup?.$crewMembersSlider?.$title?.value
                }}
                crewMembersCards={markup?.$crewMembersSlider?.$crewMemberCardsList?.items?.map((crewMemberCard => {
                    return {
                        imageProps: {
                            src: parseCCSImage(crewMemberCard.$image?.value),
                            blurhash: crewMemberCard.$image?.value?.data.attributes.blurhash
                        },
                        nameSpanProps: {
                            value: crewMemberCard.$nameLabel?.value
                        },
                        jobSpanProps: {
                            value: crewMemberCard.$jobLabel?.value
                        },
                        descriptionProps: {
                            text: crewMemberCard.$description?.value
                        }
                    }
                })) || []}
                leftButtonProps={{
                    iconUID: markup?.$crewMembersSlider?.$scrollLeftButton?.$icon?.value
                }}
                rightButtonProps={{
                    iconUID: markup?.$crewMembersSlider?.$scrollRightButton?.$icon?.value
                }}
            />

            <PortfolioEventsSlider
                className={styles.footer__portfolioEventsSlider}
                asideImageProps={{
                    src: parseCCSImage(markup?.$portfolioEventsSlider?.$image?.value),
                    blurhash: markup?.$portfolioEventsSlider?.$image?.value?.data.attributes.blurhash
                }}
                titleProps={{
                    value: markup?.$portfolioEventsSlider?.$title?.value
                }}
                portfolioEventCardsProps={markup?.$portfolioEventsSlider?.$portfolioEventsCardsList?.items?.map(portfolioEventsCard => {
                    return {
                        titleProps: {
                            value: portfolioEventsCard.$title?.value
                        },
                        imagesProps: portfolioEventsCard.$imagesList?.items?.map(image => {
                            return {
                                src: parseCCSImage(image.value),
                                blurhash: image.value?.data.attributes.blurhash
                            }
                        }),
                        link: portfolioEventsCard.$link?.value
                    }
                }) || []}
                hyperLinkProps={{
                    text: markup?.$portfolioEventsSlider?.$toFullLink?.$span?.value,
                    iconUID: markup?.$portfolioEventsSlider?.$toFullLink?.$icon?.value,
                    link: markup?.$portfolioEventsSlider?.$toFullLink?.$link?.value,
                }}
            />

            <div className={styles.footer__largeLeadSection}>
                <LeadForm
                    className={styles.largeLeadSection__leadForm}
                    title={markup?.$largeLeadSection?.$title?.value}
                    description={markup?.$largeLeadSection?.$description?.value}
                    confirmButtonLabel={markup?.$largeLeadSection?.$confirmButton?.$label?.value}
                    inputLine1PlaceHolder={markup?.$largeLeadSection?.$input1?.$placeholder?.value}
                    inputLine2PlaceHolder={markup?.$largeLeadSection?.$input2?.$placeholder?.value}
                    inputLine3PlaceHolder={markup?.$largeLeadSection?.$inputArea?.$placeholder?.value}
                    pdfLabel={markup?.$largeLeadSection?.$fileHyperLink?.$text?.value}
                    pdfPath={parseCCSImage(markup?.$largeLeadSection?.$fileHyperLink?.$file?.value)}
                />
                <div className={styles.largeLeadSection__divider}/>
                <div className={styles.largeLeadSection__infoContainer}>
                    <div className={styles.largeLeadSection__infoSections}>
                        {markup?.$largeLeadSection?.$iconSpanLinksListContainerList?.items?.map((iconSpanLinksListContainer) => (
                            <div className={styles.largeLeadSection__iconSpanLinksListContainer}>
                                <Title
                                    className={styles.largeLeadSection__infoTitle}
                                    value={iconSpanLinksListContainer.$title?.$span?.value}
                                />
                                {iconSpanLinksListContainer.$iconSpanLinksList.items?.map(iconSpanLink => (
                                    <IconSpanLink
                                        className={styles.largeLeadSection__iconSpanLink}
                                        iconUID={iconSpanLink.$icon?.value}
                                        text={iconSpanLink.$span?.value}
                                        link={iconSpanLink.$link?.value}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className={styles.largeLeadSection__mapContainer}>
                        <Map 
                            className={styles.largeLeadSection__map}
                            defaultState={{ 
                                center: [
                                    +(markup?.$largeLeadSection?.$map?.x || 0),
                                    +(markup?.$largeLeadSection?.$map?.y || 0)
                                ], 
                                zoom: 17
                            }}
                        />
                        <AsideTitle
                            className={styles.largeLeadSection__asideTitleSection}
                            title={markup?.$largeLeadSection?.$asideMeta?.$title?.value || ''}
                            subtitle={markup?.$largeLeadSection?.$asideMeta?.$subtitle?.value}
                        />
                    </div>
                </div>
            </div>

            <FAQContainer
                className={styles.footer__fAQContainer}
                titleProps={{
                    value: markup?.$fAQContainer?.$title?.value
                }}
                fAQCardsProps={markup?.$fAQContainer?.$fAQCardsList?.items?.map((fAQCard, index) => {
                    return {
                        keySpanProps: {
                            value: String(index + 1)
                        },
                        questionSpanProps: {
                            value: fAQCard.$title?.value
                        },
                        descriptionProps: {
                            text: fAQCard.$description?.value
                        }
                    }
                }) || []}
            />

            <ShortNavigation
                className={styles.footer__shortNavigation}
                iconSpanLinksProps={markup?.$shortNavigation?.items?.map((iconSpanLink) => {
                    return {
                        text: iconSpanLink.$span?.value,
                        iconUID: iconSpanLink.$icon?.value,
                        link: iconSpanLink.$link?.value
                    }
                })}
            />

            <NavigationSection
                className={styles.footer__navigationSection}
                navigationContainersProps={markup?.$navigationContainersList?.items?.map(navigationContainer => {
                    return {
                        columns: navigationContainer.columns,
                        titleProps: {
                            text: navigationContainer.$title?.$span?.value,
                            iconUID: navigationContainer.$title?.$icon?.value,
                            link: navigationContainer.$title?.$link?.value
                        },
                        navigationContainerColumnsProps: navigationContainer.$iconSpanLinksListList?.items?.map(iconSpanLinksList => {
                            return {
                                titleProps: {
                                    text: iconSpanLinksList.$title?.$span?.value,
                                    iconUID: iconSpanLinksList.$title?.$icon?.value,
                                    link: iconSpanLinksList.$title?.$link?.value,
                                },
                                iconSpanLinks:  iconSpanLinksList.$iconSpanLinksList.items?.map(iconSpanLink => {
                                    return {
                                        text: iconSpanLink.$span?.value,
                                        iconUID: iconSpanLink.$icon?.value,
                                        link: iconSpanLink.$link?.value
                                    }
                                })
                            }
                        }) || []
                    }
                }) || []}
            />

            <div className={styles.footer__companyMark}>
                <Span
                    className={styles.companyMark__title}
                    value={markup?.$companyMark?.value}
                />
            </div>
        </div>
    )
}

export default Footer;