import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Title } from '../title';
import IconSpanLink from '../IconSpanLink';

const MetaContainer: React.FC<MetaContainerProps> = ({
    className,
    asideTitleProps,
    metaCardsProps
}) => {
    return (
        <div className={cn(className, styles.metaContainer)}>
            <div className={styles.metaContainer__aside}>
                <div/>
                <Title 
                    className={cn(styles.metaContainer__asideTitle, styles.label_v1)}
                    {...asideTitleProps}
                />
            </div>
            <div className={styles.metaContainer__main}>
                {metaCardsProps.map(metaCard => (
                    <IconSpanLink
                        className={styles.metaContainer__metaCard}
                        text={metaCard.titleProps.value}
                        iconUID={metaCard.iconProps.iconUID}
                    />
                ))}
            </div>
        </div>
    )
}

export default MetaContainer;