import React from 'react';
import styles from './ShortLeadSection.module.scss';
import cn from 'classnames';
import { Button, HyperLink, HyperLinkProps, InputLine, InputLineProps, Title } from '../../../shared';

export type ShortLeadSectionProps = {
    className?: string;
    titleProps: TitleProps;
    input1Props: InputLineProps;
    input2Props: InputLineProps;
    confirmButtonProps: ButtonProps;
    fileHyperLinkProps: HyperLinkProps;
}

export const ShortLeadSection: React.FC<ShortLeadSectionProps> = ({
    className,
    titleProps,
    input1Props,
    input2Props,
    confirmButtonProps,
    fileHyperLinkProps
}, ...args) => {

    return (
        <div className={cn(className, styles.shortLeadSection)} {...args}>
            <Title 
                className={styles.shortLeadSection__title}
                {...titleProps}
            />
            <div className={styles.shortLeadSection__form}>
                <InputLine
                    className={styles.shortLeadSection__nameInputLine}
                    {...input1Props}/>
                <InputLine
                    className={styles.shortLeadSection__phoneNumberInputLine}
                    {...input2Props}/>
                <Button
                    className={styles.shortLeadSection__confirmButton}
                    {...confirmButtonProps}
                />
            </div>
            <div className={styles.shortLeadSection__divider}>
                <div/>
                <span>{'или'}</span>
                <div/>
            </div>
            <HyperLink
                className={styles.shortLeadSection__fileHyperLink}
                {...fileHyperLinkProps}
            />
            {/* <a
                className={styles.shortLeadSection__pdfLink}
                href={''}>
                    {''}
            </a> */}
        </div>
    )
}