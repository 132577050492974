import styles from "./styles.module.scss"
import cn from "classnames"

type Props = {
    className?: string
    value: number
    setValue: (value: number) => void
}

export const Clicker: React.FC<Props> = (props) => {

    const increment = (value: number) => {
        props.setValue(value + 1)
    }

    const decrement = (value: number) => {
        if (value > 1) {
            props.setValue(value - 1)
        }
    }

    return (
        <div className={cn(props.className, styles.clicker)}>
            <button
                onClick={() => decrement(props.value)}>
                    {/* <SmallMinusIcon/> */}
            </button>
            <div>
                <span>{props.value}</span>
            </div>
            <button
                onClick={() => increment(props.value)}>
                    {/* <SmallPlusIcon/> */}
            </button>
        </div>
    )
}