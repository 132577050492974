import React from 'react';
import styles from './ImagesSlider.module.scss';
import cn from 'classnames';
import { Image } from '../image/Image';

type Props = {
    className?: string,
    srcs?: string[]
}

export const ImagesSlider: React.FC<Props> = (props) => {
    const handleImageClick = (src: string) => {

    }

    return (
        <div className={cn(props.className, styles.imagesSlider)}>
            <div className={styles.imagesSlider__imagesWrapper}>
                {props.srcs?.map((src, index) => (
                    <div>
                        <Image
                            className={styles.imagesSlider__image}
                            src={src}
                            key={index}
                            onClick={() => handleImageClick(src)}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}