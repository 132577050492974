import React from 'react';
import styles from './BoardItem.module.scss';
import cn from 'classnames';
import { Image } from '../../../shared';
import { Link } from 'react-router-dom';

type Props = {
    className?: string,
    imageSrc?: string,
    label: string,
    path: string
    imageProps?: ImageProps
}

const BoardItem: React.FC<Props> = ({
    className,
    imageSrc,
    imageProps,
    label,
    path
}) => {
    return (
        <Link to={path}>
            <div className={cn(className, styles.boardItem)}>
                <Image
                    className={styles.boardItem__image}
                    {...imageProps}
                />
                <span>{label}</span>
            </div>
        </Link>
    )
}

export default BoardItem;