import React from 'react';
import styles from './Description.module.scss';
import cn from 'classnames';

export type DescriptionProps = {
    className?: string
    text?: string
}

export const Description: React.FC<DescriptionProps> = ({
    className,
    text
}, ...args) => {
    return (
        <div className={cn(className, styles.description)}>
            {text?.split("\n\n").map((value, valueIndex) => {
                return (
                    <p key={valueIndex}>{value.split("\n").map((it, index) => {
                        return (
                            <span key={index}>{it}<br/></span>
                        )
                    })}</p>
                )
            })}
        </div>
    )
}