import React from 'react';
import styles from './styles.module.scss';
import { Description, Image } from '../../../shared';
import LeadShortSection from '../../../features/lead/shortSection/ui/LeadShortSection';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';

const Main6Block: React.FC<Main6BlockProps> = ({
    markup
}) => {

    return (
        <div className={styles.mainBlock6}>
            <div className={styles.mainBlock6__header}>
                <Image
                    className={styles.mainBlock6__baseImage}
                    src={parseCCSImage(markup?.$header?.$image?.value)}
                />
                <div className={styles.mainBlock6__textWrapper}>
                    <h1 className={styles.mainBlock6__title}>{markup?.$header?.$title?.value}</h1>    
                </div>
            </div>
            <LeadShortSection
                className={styles.mainBlock6__leadShortSection}
                title={markup?.$shortLeadSection?.$title?.value}
                inputLine1PlaceHolder={markup?.$shortLeadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$shortLeadSection?.$input2?.$placeholder?.value}
                confirmButtonLabel={markup?.$shortLeadSection?.$confirmButton?.$label?.value}
                pdfPath={parseCCSImage(markup?.$shortLeadSection?.$fileHyperLink?.$file?.value)}
                pdfLabel={markup?.$shortLeadSection?.$fileHyperLink?.$text?.value}
            />
            <div className={styles.mainBlock6__main}>
                <div className={styles.mainBlock6__imagesSection}>
                    <ul>
                        {markup?.$imagesList?.items?.filter((it, index) => index  % 2 === 0 )?.map((it, index) => (
                            <li key={index}>
                                <Image
                                    className={styles.mainBlock6__image}
                                    src={parseCCSImage(it.value)}
                                />
                            </li>
                        ))}
                    </ul>
                    <ul>
                        {markup?.$imagesList?.items?.filter((it, index) => index  % 2 === 1 )?.map((it, index) => (
                            <li key={index}>
                                <Image
                                    className={styles.mainBlock6__image}
                                    src={parseCCSImage(it.value)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.mainBlock6__infoSection}>
                    <Description
                        className={styles.mainBlock6__description}
                        text={markup?.$description?.value}
                    />
                </div>
            </div>
        </div>
    )
}

export default Main6Block;