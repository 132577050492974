import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './Image.module.scss';
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { Blurhash } from 'react-blurhash';

export const Image: React.FC<ImageProps> = ({
    className,
    wrapperClassName,
    src,
    blurhash,
    ref,
    onClick,
    onLoad
}, ...args) => {

    const [isFailure, setFailure] = useState(false)
    const [isPending, setPending] = useState(true)

    const __onLoad = () => {
        setPending(false)
        // if (!!onLoad) onLoad()

    }

    return (
        <>
            <div
                className={className}
                style={isPending ? {display: "flex", background: "red"} : {display: "none"}}    
            >
                <Blurhash
                    className={styles.loadingImage}
                    hash={blurhash || 'c1Eyb[~qfQ?bj[fQfQfQfQ?bj[fQfQfQfQ'}
                    ref={ref}
                    height={'100%'}
                    width={'100%'}
                    // resolutionX={32}
                    // resolutionY={32}
                    // punch={1}
                />
            </div>

                {/* <Spin
                    rootClassName={styles.loadingImage__spin}
                    // className={styles.loadingImage__spin}
                    indicator={<LoadingOutlined/>}
                    size='large'
                /> */}
            <img
                className={cn(
                    className, 
                    styles.image
                )}
                ref={ref}
                style={isFailure || isPending ? {display: "none"} : {display: "block"}}
                onLoad={__onLoad}
                onError={() => setFailure(true)}
                alt=''
                src={src}
                onClick={onClick}
                draggable={false}
            />
            <div ref={ref}>
                <Empty
                    className={cn(className, styles.loadingImage)}
                    style={isFailure ? {display: "block"} : {display: "none"}}
                >
                    ОШИБКА
                </Empty>
            </div>
        </>
    )
}