import React from "react"
import styles from "./styles.module.scss"
import cn from "classnames"
import { strings } from "../lib"
import { Button, IconSpanLink, Image, Span, useAppStore } from "../../../shared"
import { Link } from "react-router-dom"
import { parseCCSImage } from "../../../core/ccs/utils/parseCCSImages"
import { usePageDataStore } from "../../../shared/lib/usePageDataStore"

const Header: React.FC<HeaderProps> = ({
    className,
    markup
}) => {
    const { appStore } = useAppStore()
    const { pageDataStore } = usePageDataStore()
    return (
        <div className={cn(className, styles.header)}>
            <div className={styles.header__topContainer}>
                <ul className={cn(styles.topContainer__navList, styles.horizontalList)}>
                    {markup?.$shortNavigation?.items?.map((shortNavigationItem) => (
                        <li>
                            <IconSpanLink
                                text={shortNavigationItem.$span?.value}
                                iconUID={shortNavigationItem.$icon?.value}
                                link={shortNavigationItem.$link?.value}
                            />
                        </li>    
                    ))}
                </ul>
                <ul className={cn(styles.topContainer__contactsList, styles.horizontalList)}>
                    {markup?.$contactsList?.items?.map((contact) => (
                        <IconSpanLink
                            text={contact.$span?.value}
                            iconUID={contact.$icon?.value}
                            link={contact.$link?.value}
                        />
                    ))}
                </ul>
            </div>
            <div className={styles.header__divider}/>
            <div className={styles.header__bottomContainer}>
                <Image
                    className={styles.header__logo}
                    src={parseCCSImage(markup?.$logo?.value)}
                    blurhash={markup?.$logo?.value?.data.attributes.blurhash}
                />
                <ul className={cn(styles.bottomContainer__navList, styles.horizontalList)}>
                    <li>
                        <Button
                            className={styles.button_v1}
                            label={markup?.$menuButton?.$label?.value}
                            iconUID={markup?.$menuButton?.$icon?.value}
                            onClick={() => pageDataStore?.changeSidebarVisibility()}
                        />
                    </li>
                    {markup?.$navigation?.items?.map((navigationItem, index) => (
                        <li>
                            <IconSpanLink
                                text={navigationItem.$span?.value}
                                iconUID={navigationItem.$icon?.value}
                                link={navigationItem.$link?.value}
                            />
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.header__bredcrumb}>
                <IconSpanLink
                    className={styles.bredcrumb__item}
                    text="Главная"
                    iconUID="home"
                    link="/home"
                />
                {window.location.pathname.split('/').filter(it => it).map((path, index) => {
                    const route = appStore?.routes.find(it => {
                        return it.path === window.location.pathname.split('/').slice(0, index + 2).join('/')
                    })
                    return (
                        <>
                            <div className={styles.bredcrumb__itemDivider}/>
                            <IconSpanLink
                                className={styles.bredcrumb__item}
                                text={route?.title}
                                link={route?.path}
                            />
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default Header;