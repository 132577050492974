import React, { useContext } from "react"
import CCPageDataStore from "../model/CCPageDataStore"

const PageDataStoreContext = React.createContext({} as {
    pageDataStore?: CCPageDataStore
})

type Props = {
    pageDataStore: CCPageDataStore,
    children: React.ReactNode
}

export const PageDataStoreProvider: React.FC<Props> = ({
    pageDataStore,
    children
}) => {
    const context = {
        pageDataStore: pageDataStore
    }
    return <PageDataStoreContext.Provider value={context}>{children}</PageDataStoreContext.Provider>
}

export const usePageDataStore = () => {
    const context = useContext(PageDataStoreContext);
    return context;
}