import React from "react"
import styles from "./styles.module.scss"
import cn from "classnames"

type Props = {
    className?: string,
    value: string
    setValue: (value: string) => void
    placeholder?: string
}

export const InputField: React.FC<Props> = (props) => {

    const handleChanges = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.setValue(e.target.value)
    }

    return (
        <textarea
            className={cn(props.className, styles.inputField)}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleChanges}/>
    )
}