import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import cn from "classnames"
import { 
    Button, 
    Description, 
    Tabs, 
    Title
} from "../../../shared"
import ShopFilter from "../../../features/shop/filter/ui/ShopFilter"
import ShopItemCard from "../../../features/shop/itemCard/ui/ShopItemCard"
import { usePageDataStore } from "../../../shared/lib/usePageDataStore"
import { parseCCSImage } from "../../../core/ccs/utils/parseCCSImages"


const Main1Block: React.FC<Main1BlockProps> = ({
    markup
}) => {
    const { pageDataStore } = usePageDataStore()

    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedFilter, setSelectedFilter] = useState(undefined)


    const handleHeaderButtonClick = () => {

    }

    const handleLoadMoreClick = () => {

    }

    useEffect(() => {
        const datasetKey = markup?.$articleCardsListsList?.items?.at(selectedTab)?.__dataset;

        if (datasetKey) {
            const dataset = pageDataStore?.datasetsStore[datasetKey];

            if (!!dataset) {
                pageDataStore?.updateDataset(
                    datasetKey,
                    undefined,
                    undefined,
                    dataset?.filter,
                    !!selectedFilter ? ['filters[tags][uid][$eq]=' + selectedFilter] : []
                )
                pageDataStore?.refreshData(dataset).then((result) => {
                    if (result) {
                        pageDataStore.refreshMarkup()
                    }
                })
            }
        }
    }, [selectedFilter, selectedTab])

    return (
        <div className={cn(styles.mainBlock1__mainBlock1, styles.mainBlock1)}>
            <div className={styles.mainBlock1__header}>
                <Title
                    className={styles.mainBlock1__title}
                    value={markup?.$title?.value}
                />
                <Button
                    className={cn(styles.mainBlock1__headerButton, styles.button_v2)}
                    onClick={handleHeaderButtonClick}
                    label={markup?.$infoButton?.$label?.value}
                    iconUID={markup?.$infoButton?.$icon?.value}
                />
            </div>
            <Tabs
                className={styles.mainBlock1__tabs}
                items={markup?.$tabs?.$tabItemsList.items?.map((tab) => {
                    return {
                        label: tab.$label?.value,
                    }
                }) || []}
                selected={selectedTab}
                onChange={(e) => setSelectedTab(e)}
            />
            <div className={styles.mainBlock1__main}>
                <ShopFilter
                    className={styles.mainBlock1__filters}
                    title={markup?.$filter?.$title?.value}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                    filters={[
                        [{
                            key: undefined,
                            text: 'ВСЕ'
                        }], 
                        markup?.$filter?.$filterItemsList?.items?.map((filter, index) => {
                            return {
                                key: filter.$uid?.value,
                                text: filter.$title?.value || ''
                            }
                        }) || []]}
                />
                <div className={styles.mainBlock1__shopContainer}>
                    <Description
                        className={styles.mainBlock1__description}
                        text={markup?.$description?.value}
                    />
                    <div className={styles.mainBlock1__itemsContainer}>
                        {markup?.$articleCardsListsList?.items?.at(selectedTab)?.items?.map((it, index) => (
                            <ShopItemCard
                                className={styles.mainBlock1__itemCard}
                                id={0}
                                name={it.$title?.value || ''}
                                price={+(it.$priceLabel?.value || 0)}
                                weight={+(it.$weightLabel?.value || 0)}
                                baseImageSrc={parseCCSImage(it.$image?.value)}
                                path={it.$link?.value}
                                onPurchaseClick={() => {}}
                            />
                        ))}
                    </div>
                    <Button
                        className={cn(styles.mainBlock1__loadMoreButton, styles.button_v3)}
                        label={'ЗАГРУЗИТЬ ЕЩЁ'}
                        // style={state.data.fullLoad || state.loading ? {display: 'none'} : {}}
                        onClick={handleLoadMoreClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default Main1Block;