import React from 'react';
import styles from './DishItemsList.module.scss';
import cn from 'classnames';
import markup from '../lib/markup.json';

type Props = {
    className?: string,
    items?: { 
        name: string,
        value: string
    }[]
}

const DishItemsList: React.FC<Props> = ({
    className,
    items
}) => {
    return (
        <ul className={cn(className, styles.dishItemsList)}>
            {items?.map((it, index) => (
                <li
                    className={styles.dishItemsList__dishItemsListItem}
                    key={index}
                >
                    <div>
                        <span className={styles.dishItemsListItem__nameLabel}>{it.name}</span>
                        <span className={styles.dishItemsListItem__valueLabel}>{it.value} {markup.dishItemsListItem.suffix}</span>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default DishItemsList;