import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import cn from "classnames"
import { Description, ImagesHolder, Button } from "../../../shared"
import DishItemsList from "../../../features/dishitemslist/ui/DishItemsList"
import ShopRecommendationsSection from "../../../features/shopRecommendationsSection/ui/ShopRecommendationsSection"
import { parseCCSImage, parseCCSImagesList } from "../../../core/ccs/utils/parseCCSImages"
import { useParams } from "react-router-dom"
import LeadShortSection from "../../../features/lead/shortSection/ui/LeadShortSection"
import { RecommendationContainer } from "../../../features"
import { usePageDataStore } from "../../../shared/lib/usePageDataStore"

const Main10Block: React.FC<Main10BlockProps> = ({
    markup
}) => {
    const { id } = useParams()
    const { pageDataStore } = usePageDataStore()

    const [selectedImage, setSelectedImage] = useState(0)

    const deliveryInfoButtonClick = () => {

    }

    const handlePurchaseButtonClick = () => {

    }

    useEffect(() => {
        if (!!id) {
            pageDataStore?.setUpPageParam(id)
            pageDataStore?.refreshDatasetsAndData().then((result) => {
            })
        }
    }, [])

    // useEffect(() => {
    //     if (!!id && !!props.blockData?.shopItemDetailSection?.itemCollection?.data?.attributes?.uid) {
    //         dispatch(fetchCollectionItem({
    //             collectionName: props.blockData?.shopItemDetailSection?.itemCollection?.data?.attributes?.uid,
    //             id: +id
    //         }))
    //     }
    // }, [props.blockData?.shopItemDetailSection?.itemCollection?.data?.attributes?.uid]);

    return (
        <div className={styles.mainBlock10}>
            <div className={styles.mainBlock10__header}>
                <h1 className={cn(styles.header__title, styles.title_v2)}>
                    {markup?.$title?.value}
                </h1>
                <Button
                    className={cn(styles.mainBlock10__deliveryInfoButton, styles.button_v2)}
                    label={markup?.$infoButton?.$label?.value}
                    iconUID={markup?.$infoButton?.$icon?.value}
                    onClick={deliveryInfoButtonClick}
                />
            </div>
            <div className={styles.mainBlock10__mainContainer}>
                <ImagesHolder
                    className={styles.mainBlock10__imagesHolder}
                    imagesUrls={markup?.$imagesSlider?.$imagesList?.items?.map(image => parseCCSImage(image.value) || '')}
                    selected={selectedImage}
                    setSelected={setSelectedImage}/>
                <div className={styles.mainBlock10__infoContainer}>
                    <DishItemsList
                        className={styles.mainBlock10__dishItemsList}
                        items={markup?.$articleElementsPicker?.$articleElementCardsList?.items?.map(articleElementCard => {
                            return {
                                name: articleElementCard.$nameLabel?.value || '',
                                value: articleElementCard.$valueLabel?.value || ''
                            }
                        })}
                    />
                    <Description
                        className={styles.mainBlock10__description}
                        text={markup?.$decription?.value}/>
                    <div className={styles.mainBlock10__purchaseContainer}>
                        <div className={styles.mainBlock10__purchaseInfoContainer}>
                            <span className={styles.mainBlock10__weightLabel}>{"Вес:"} {markup?.$weightLabel?.value} {"г"}</span>
                            <span className={styles.mainBlock10__priceLabel}>{new Intl.NumberFormat().format(+(markup?.$priceLabel?.value || 0))}{"р"}</span>
                        </div>
                        <Button
                            className={cn(styles.mainBlock10__addToCartButton, styles.button_v3)}
                            label={markup?.$purchaseButton?.$label?.value}
                            iconUID={markup?.$purchaseButton?.$icon?.value}
                            onClick={handlePurchaseButtonClick}
                        />
                    </div>
                </div>
            </div>
            <LeadShortSection
                className={styles.mainBlock10__leadShortSection}
                title={markup?.$shortLeadSection?.$title?.value}
                inputLine1PlaceHolder={markup?.$shortLeadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$shortLeadSection?.$input2?.$placeholder?.value}
                confirmButtonLabel={markup?.$shortLeadSection?.$confirmButton?.$label?.value}
                pdfPath={parseCCSImage(markup?.$shortLeadSection?.$fileHyperLink?.$file?.value)}
                pdfLabel={markup?.$shortLeadSection?.$fileHyperLink?.$text?.value}
            />
            <RecommendationContainer
                className={styles.mainBlock10__shopRecommendationsSection}
                titleProps={{
                    value: markup?.$recommendationsSection?.$title?.value
                }}
                articlesProps={markup?.$recommendationsSection?.$articleCardsList?.items?.map((articleCard) => {
                    return {
                        weight: articleCard.$weightLabel?.value,
                        price: articleCard.$priceLabel?.value,
                        name: articleCard.$title?.value,
                        imageSrc: parseCCSImage(articleCard.$image?.value),
                    }
                }) || []}
            />
        </div>
    )
}

export default Main10Block;