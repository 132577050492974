import { CCSError } from "../../../shared"
import CCSBlocks from "../lib/CCSBlocks"
import CCSCollections from "../lib/CCSCollections"
import CCSRequestFilter from "../lib/CCSRequestFilter"
import CCSResponseWrapper from "../lib/CCSResponseWrapper"
import generateCCSRequestUrl from "../utils/generateCCSRequestUrl"

const fetchCCS = async (
    entityName: string,
    params: string[],
    filters: CCSRequestFilter[],
    id?: number,
) => {
    try {
        const response = await fetch(generateCCSRequestUrl(entityName, params, filters, id))

        if (response.status === 200) {
            const dto = await response.json()
            return {
                status: response.status,
                body: dto
            } as CCSResponseWrapper
        } else {
            return new CCSError(response.status, response.statusText)
        }
    } catch (e: any) {
        return new CCSError(409, e.toString())
    }
}

export default fetchCCS