import { createBrowserRouter } from "react-router-dom";
import CCREST from "../../api/CCREST";
import CCError from "../../lib/CCError";


class CCAppDataStore {
    private __cCREST: CCREST;
    private __routes: CCRoute[];
    private __isLoading1: boolean;

    get isLoading() {
        return this.__isLoading1;
    }

    set isLoading(value) {
        this.__isLoading1 = value;
    }

    get routes() {
        return this.__routes;
    }

    constructor() {
        this.__cCREST = new CCREST()
        this.__routes = [];
        this.__isLoading1 = false
    }

    updateRoutes = async () => {
        this.__isLoading1 = true
        const result = await this.__cCREST.fetch(
            'navigation/render/main-navigation',
            [],
            undefined,
            undefined
        )
        if (result instanceof CCError) {
            // this.__isLoading = false
            return result
        }
        if (!Array.isArray(result.body)) {
            return new CCError(403, 'Ошибка')
        }

        let newRoutes: CCRoute[] = []
        result.body.forEach((route) => {
            if (!!route?.related?.id) {
                newRoutes.push(
                    {
                        pageId: route.related.id,
                        path: '/' + this.buildPagePath(route, result.body, [route.id]),
                        title: route?.title,
                        hasHeader: route.hasHeader,
                        hasFooter: route.hasFooter,
                        hasSidebar: route.hasSidebar,
                    }
                )
            }
        })
        this.__routes = newRoutes;
        // this.__routes = (result.body as any[]).map((route) => {
        //     return {
        //         id: route.id,
        //         path: route.path,
        //         mainBlockComponent: route.attributes?.ui?.at(0)?.__component
        //     }
        // })
        // this.__isLoading = false
    }

    buildPagePath = (currentRoute: any, routes: any[], path: string[]): any => {
        const parentId = currentRoute?.parent?.id
        if (parentId === undefined) {
            const result = path.map((id) => routes.find((it) => it.id === id).path)
            return result.reverse().join('/')
        }

        const parent = routes.find((it) => it.id === parentId)
        path.push(parent.id)
        return this.buildPagePath(parent, routes, path)
    }
}

export default CCAppDataStore;