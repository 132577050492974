import styled from "styled-components";

type GridProps = {
    gridTemplateColumns?: string,
    gridTemplateRows?: string,
    rowGap?: string
    columnGap?: string
    margin?: string
    children: React.ReactNode;
}

const Grid: React.FC<GridProps> = ({
    children
}, ...args) => {
    return (
        <div {...args}>
            {children}
        </div>
    )
}

export default styled(Grid)<GridProps>`
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns};
    grid-template-rows: ${props => props.gridTemplateRows};
    row-gap: ${props => !!props.rowGap ? props.rowGap : 0};
    column-gap: ${props => !!props.columnGap ? props.columnGap : 0};
`