import React from 'react';
import styles from './BoardCard3.module.scss';
import cn from 'classnames';
import { Description, Image } from '../../../shared';

type Props = {
    className?: string;
    key?: any,
    baseImageSrc?: string
    title?: string
    description?: string
}

export const BoardCard3: React.FC<Props> = (props) => {
    return (
        <div className={cn(props.className, styles.boardCard3)}>
            <Image
                className={styles.boardCard3__baseImage}
                key={props.key}
                src={props.baseImageSrc}
            />
            <h2 className={styles.boardCard3__title}>{props.title}</h2>
            <Description
                className={styles.boardCard3__description}
                text={props.description}
            />
        </div>
    )
}