import React from 'react';
import styles from './RecommendationContainer.module.scss';
import cn from 'classnames';
import { Title } from '../../../shared';
import { ArticleCard } from '../../../entities';

export type RecommendationContainerProps = {
    className?: string;
    titleProps: TitleProps;
    articlesProps: ArticleCardProps[];
}

export const RecommendationContainer: React.FC<RecommendationContainerProps> = ({
    className,
    titleProps,
    articlesProps
}, ...args) => {
    return (
        <div className={cn(className, styles.recommendationContainer)} {...args}>
            <Title
                className={styles.recommendationContainer__title}
                {...titleProps}
            />
            <div className={styles.recommendationContainer__content}>
                {articlesProps.map((articleProps, index) => (
                    <ArticleCard
                        className={styles.recommendationContainer__item}
                        {...articleProps}
                    />
                ))}
            </div>
        </div>
    )
}