import styles from './MetaCardsList.module.scss';
import cn from 'classnames';
import { MetaCard } from '../../../shared';

export type MetaCardsListProps = {
    className?: string;
    items: MetaCardProps[];
}

export const MetaCardsList: React.FC<MetaCardsListProps> = ({
    className,
    items
}, ...args) => {
    return (
        <div className={cn(className, styles.metaCardsList)} {...args}>
            {items.map((metaCardProps, index) => (
                <MetaCard
                    className={styles.metaCardsList__item}
                    key={index}
                    {...metaCardProps}
                />
            ))}
        </div>
    )
}