import CCSResponseWrapper from "../../core/ccs/lib/CCSResponseWrapper";
import CCREST from "../api/CCREST";
import CCError from "../lib/CCError";
import { makeAutoObservable } from "mobx"



class CCPageDataStore {
    private __cCREST: CCREST;

    private __pageId: number;

    private __data: {
        [key: string]: CCEntity
    }
    private __datasetsStore: {
        [key: string] : CCRelationStoreItem
    }
    
    private __markup: {
        header: any;
        blocks: any[];
        footer: any;
        sidebar: any
    }

    private __initMarkup: {
        header: any;
        blocks: any[];
        footer: any;
        sidebar: any
    }

    private __template: string | null //BE DELETED

    private __hasHeader: boolean
    private __hasFooter: boolean
    private __hasSidebar: boolean

    private __pageParam?: string
    private __isSidebarOpen: boolean

    get pageId() {
        return this.__pageId;
    }

    get template() {
        return this.__template;
    }

    get data() {
        return this.__data;
    }

    get datasetsStore() {
        return this.__datasetsStore;
    }

    get markup() {
        return this.__markup
    }

    get hasHeader() {
        return this.__hasHeader
    }

    get hasFooter() {
        return this.__hasFooter
    }

    get hasSidebar() {
        return this.__hasSidebar
    }

    get pageParam() {
        return this.__pageParam
    }

    get isSidebarOpen() {
        return this.__isSidebarOpen
    }

    constructor (
        pageId: number,
        hasHeader?: boolean,
        hasFooter?: boolean,
        hasSidebar?: boolean
    ) {
        makeAutoObservable(this)
        this.__data = {}
        this.__datasetsStore = {}
        this.__pageId = pageId
        this.__cCREST = new CCREST()
        this.__template = null
        this.__markup = {
            header: undefined,
            blocks: [],
            footer: undefined,
            sidebar: undefined
        }
        this.__initMarkup = {
            header: undefined,
            blocks: [],
            footer: undefined,
            sidebar: undefined
        }
        this.__hasHeader = hasHeader || false
        this.__hasFooter = hasFooter || false
        this.__hasSidebar = hasSidebar || false
        this.__pageParam = undefined
        this.__isSidebarOpen = false
        
    }

    changeSidebarVisibility = () => {
        this.__isSidebarOpen = !this.__isSidebarOpen
    }

    initialize = async () => {
        try {
            if (this.__hasHeader) {
                await this.initHeader()
            }
            if (this.__hasFooter) {
                await this.initFooter()
            }

            if (this.__hasSidebar) {
                await this.initSidebar()
            }

            const result = await this.__cCREST.fetch(
                'pages',
                ['populate=deep,15'],
                undefined,
                this.__pageId
            )
            if (result instanceof CCError) {
                return result;
            }
            result.body?.data?.attributes?.datasetsStore?.items &&
                (result.body?.data?.attributes?.datasetsStore?.items as any[]).forEach((it, index) => {
                    this.__datasetsStore[it.key] = it
            })

            let promises = []
            for (let datasetKey of Object.keys(this.__datasetsStore)) {
                promises.push(this.refreshData(this.__datasetsStore[datasetKey]))
            }
            
            await Promise.all(promises)

            this.__initMarkup.blocks = (result.body?.data?.attributes?.markup as any[])
            
            this.refreshMarkup()
            // this.__markup.mainBlocks = this.updateMarkup(this.__markup?.at(0), undefined)
        } catch (e: any) {
            return new CCError(500, '[INIT ERROR] – ' + e)
        }
        // this.__markup[0] = this.updateMarkup(this.__markup?.at(0), undefined)
        return true
    }

    initHeader = async () => {
        const result = await this.__cCREST.fetch(
            'header',
            ['populate=deep,15'],
            undefined,
            undefined
        )

        if (result instanceof CCError) {
            return result
        }

        this.__initMarkup.header = (result.body?.data?.attributes?.markup as HeaderMarkup)
    }

    initFooter = async () => {
        const result = await this.__cCREST.fetch(
            'footer',
            ['populate=deep,15'],
            undefined,
            undefined
        )

        if (result instanceof CCError) {
            return result
        }

        this.__initMarkup.footer = (result.body?.data?.attributes?.markup as FooterMarkup)
    }

    initSidebar = async () => {
        const result = await this.__cCREST.fetch(
            'sidebar',
            ['populate=deep,15'],
            undefined,
            undefined
        )

        if (result instanceof CCError) {
            return result
        }

        this.__initMarkup.sidebar = (result.body?.data?.attributes?.markup as SidebarMarkup)
    }

    refreshDatasetsAndData = async () => {
        const promises: Promise<boolean | CCError>[] = [] 
        Object.keys(this.__datasetsStore).forEach((datasetKey) => {
            this.updateDataset(datasetKey)
            const updateResult = this.refreshData(this.__datasetsStore[datasetKey])
            promises.push(updateResult)
        })
        const results = await Promise.all(promises)
        if (results.find(it => it instanceof CCError)) {
            return new CCError(403, 'REFRESH ERROR')
        }
        const markupRefreshResult = this.refreshMarkup()
        return true;
    }

    updateDataset = (
        key: string, 
        object?: string,
        objectId?: number,
        filter?: string,
        addictionFilter?: string[]
    ) => {
        try {
            const newDataset = this.__datasetsStore[key]
            if (!newDataset) {
                return new CCError(404, 'Dataset not found')
            }
            newDataset.object = object || newDataset.object
            newDataset.objectId = objectId || newDataset.objectId
            newDataset.filter = filter || newDataset.filter
            newDataset.addictionFilter = addictionFilter || newDataset.addictionFilter

            if (!!this.__pageParam) {
                if (newDataset.filter && newDataset.filter.includes(':urlKey')) {
                    newDataset.filter = newDataset.filter.replace(':urlKey', this.__pageParam)
                }
                if (newDataset.objectId && String(newDataset.objectId).includes(':urlKey')) {
                    newDataset.objectId = +(String(newDataset.objectId).replace(':urlKey', this.__pageParam))
                }
            }

            this.__datasetsStore[key] = newDataset;
            return true
        } catch (e: any) {
            return new CCError(500, 'updateDataset – ' + e)
        }

    }

    buildFullFilter = (dataset: CCRelationStoreItem) => {
        if (dataset.addictionFilter) return [dataset.filter, ...dataset.addictionFilter].filter(it => !!it).join('&')
        return dataset.filter
        
    }

    refreshData = async (dataset: CCRelationStoreItem) => {
        try {
            const object = await this.__cCREST.fetch(
                dataset.object,
                ['populate=deep,10'],
                this.buildFullFilter(dataset),
                dataset.objectId
            )
            
            if (object instanceof CCError) {
                return object;
            }
            this.__data[dataset.key] = Array.isArray(object.body.data) ? (object.body.data as any[]).map(it => {
                return {
                    id: it.id,
                    ...it.attributes
                }
            }) : {
                id: object.body.data.id,
                ...object.body.data.attributes
            }
        } catch (e: any) {
            return new CCError(500, '[refreshData ERROR] – ' + e)
        }
        return true
    }

    refreshMarkup = () => {
        this.__initMarkup.blocks.forEach((block, index) => {
            const newBlock = JSON.parse(JSON.stringify(block))
            this.__markup.blocks[index] = this.updateMarkup(newBlock, newBlock.__dataset)
        })

        if (this.__hasHeader) {
            this.__markup.header = this.updateMarkup(
                JSON.parse(JSON.stringify(this.__initMarkup.header)),
                this.__initMarkup.header.__dataset
            )
        }

        if (this.__hasFooter) {
            this.__markup.footer = this.updateMarkup(
                JSON.parse(JSON.stringify(this.__initMarkup.footer)),
                this.__initMarkup.footer.__dataset
            )
        }
        if (this.__hasSidebar) {
            this.__markup.sidebar = this.updateMarkup(
                JSON.parse(JSON.stringify(this.__initMarkup.sidebar)),
                this.__initMarkup.sidebar.__dataset
            )
        }

        return true
    }

    updateMarkup = (component: any, data: any) => {
        try {
            let newComponent = component
            const keys = Object.keys(newComponent)
            const childrensKeys = keys.filter(key => key.startsWith('$'))
            const dataset = newComponent['__dataset']
            const dynamicValue = newComponent['__dynamicValue']
            let newData = data
            if (!!dynamicValue && dynamicValue !== null) {
                if (['string', 'number'].includes(typeof data[dynamicValue]) && newComponent['value']) {
                    newComponent['value'] = data ? newComponent['value'] + data[dynamicValue] : `ПУСТО ${data.attributes?.id} – ${dynamicValue}`    
                } else {
                    newComponent['value'] = data ? data[dynamicValue] : `ПУСТО ${data.attributes?.id} – ${dynamicValue}`
                }
            } else if (!!dataset && dataset !== null) {
                if (dataset.startsWith('$')) {
                    let component = newData[dataset.slice(1)]
                    if (!!component.data) {
                        if (Array.isArray(component.data)) {
                            newData = (component.data as any[]).map(it => {
                                return {
                                    id: it.id,
                                    ...it?.attributes
                                }
                            })
                        } else {
                            newData = {
                                id: component.data.id,
                                ...component.data.attributes
                            }
                        }
                    } else {
                        newData = component
                    }
                } else {
                    newData = this.data[dataset]
                }
            }

            if (!!newComponent['items'] && newComponent['items'] !== null) {
                if (!Array.isArray(newData)) {
                    newComponent['items'] = (newComponent['items'] as any[]).map(it => this.updateMarkup(it, newData))
                } else {
                    if (!!newComponent['items']?.at(0)) {
                        newComponent['items'] = newData.map((it, index) => {
                            const newItemComonent = JSON.parse(JSON.stringify(newComponent['items'][0]))
                            return this.updateMarkup(newItemComonent, it)
                        })
                    }
                }
            }

            childrensKeys.forEach(childrensKey => {

                if (newComponent[childrensKey]) {
                    newComponent[childrensKey] = this.updateMarkup(newComponent[childrensKey], newData)
                }
            })
            return newComponent
        } catch (e: any) {
            return new CCError(505, e)
        }
    }

    setUpPageParam = (pageParam: string) => {
        this.__pageParam = pageParam
    }
}

export default CCPageDataStore;