import styles from './styles.module.scss';
import cn from 'classnames';

import { Button, Text, Title } from "../../../../shared"
import { Image } from "../../../../shared/ui/image/Image"
import { Link } from 'react-router-dom';

export const ArticleCard: React.FC<ArticleCardProps> = ({
    className,
    weight,
    price,
    name,
    imageSrc,
    link
}, ...args) => {

    const __handlePurchaseButtonClick = () => {

    }

    const imageProps: ImageProps = {
        src: imageSrc
    }

    const puchaseButtonProps: ButtonProps = {
        label: 'Заказать',
        onClick: __handlePurchaseButtonClick
    }

    const weightTextProps: TextProps = {
        children: `Вес: ${weight}г.`
    }

    const priceTextProps: TextProps = {
        children: price + 'р.'
    }

    const nameTitleProps: TitleProps = {
        value: name
    }

    return (
        <div 
            className={cn(className, styles.articleCard)}
            {...args}
        >
            <Link to={link || ''} >
                <div className={styles.articleCard__imageWrapper}>
                    <Image
                        className={styles.articleCard__image}
                        {...imageProps}
                    />
                </div>
            </Link>
            <div className={styles.articleCard__meta}>
                <div className={styles.articleCard__metaTopContainer}>
                    <Text
                        className={styles.articleCard__weightText}
                        {...weightTextProps}
                    />
                    <Text
                        className={styles.articleCard__priceText}
                        {...priceTextProps}
                    />
                </div>
                <Title
                    className={styles.articleCard__nameTitle}
                    {...nameTitleProps}
                />
            </div>
            <Button
                className={cn(styles.articleCard__purchaseButton, styles.button_v3)}
                {...puchaseButtonProps}
            />
        </div>
    )
}