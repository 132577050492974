import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import CCREST from "../../api/CCREST";
import CCError from "../../lib/CCError";

export const updateRoutes = createAsyncThunk('updateRoutes', async () => {
    const result = await new CCREST().fetch(
        'pages',
        [
            'fields[0]=path',
            'populate[ui][fields][1]=__component'
        ],
        undefined,
        undefined
    )
    if (result instanceof CCError) {
        return result
    }
    return (result.body.data as any[]).map((route) => {
        return {
            id: route.id,
            path: route.attributes.path,
            mainBlockComponent: route.attributes?.ui?.at(0)?.__component
        }
    })
});