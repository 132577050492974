import React from 'react';
import styles from './AlertCard.module.scss';
import cn from 'classnames';
import { Description, DescriptionProps, Icon, Title } from '../../../shared';

export type AlertCardProps = {
    className?: string;
    iconProps: IconProps;
    titleProps: TitleProps;
    descriptionProps?: DescriptionProps;
}

export const AlertCard: React.FC<AlertCardProps> = ({
    className,
    iconProps,
    titleProps,
    descriptionProps
}, ...args) => {
    return (
        <div className={cn(className, styles.alertCard)} {...args}>
            <Icon
                className={styles.alertCard__trailingIcon}
                {...iconProps}
            />
            <div className={styles.alertCard__content}>
                <Title
                    className={styles.alertCard__title}
                    {...titleProps}
                />
                <Description
                    className={styles.alertCard__description}
                    {...descriptionProps}
                />
            </div>
        </div>
    )
}