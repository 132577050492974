import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

export const Text: React.FC<TextProps> = ({
    className,
    children
}, ...args) => {
    return (
        <span
            className={cn(className, styles.text)}
            {...args}
        >
            {children}
        </span>
    )
}