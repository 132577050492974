import { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import { 
    Header, 
    HomeBlock, 
    Main2Block, 
    Main3Block, 
    Main5Block, 
    Main6Block, 
    Main9Block, 
    Main1Block, 
    Main7Block, 
    Main10Block, 
    Footer, 
    Sidebar, 
    Main4Block
 } from "../../../widgets"
import { usePageDataStore } from "../../../shared/lib/usePageDataStore"
import { observer } from "mobx-react"
import cn from 'classnames';

const mainBlock = (blockName: string) => {
    switch (blockName) {
        case 'ui-widgets.home-block': return HomeBlock;
        case 'ui-widgets.main1-block': return Main1Block
        case 'ui-widgets.main2-block': return Main2Block
        case 'ui-widgets.main3-block': return Main3Block
        case 'ui-widgets.main4-block': return Main4Block
        case 'ui-widgets.main5-block': return Main5Block
        case 'ui-widgets.main6-block': return Main6Block
        case 'ui-widgets.main7-block': return Main7Block
        case 'ui-widgets.main9-block': return Main9Block
        case 'ui-widgets.main10-block': return Main10Block
        default: return () => <></>
    }
}

const MainPage = () => {
    const { pageDataStore } = usePageDataStore()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const __renderBlocks = () => {
        if (!!pageDataStore) {
            return pageDataStore.markup.blocks.map((block) => {
                const Block = mainBlock(block.__component)
                return <Block markup={block}/>
            })
        }
    }
    return (
        <div className={styles.mainPage}>
            {pageDataStore?.hasSidebar ? (
                    <Sidebar
                        className={cn(styles.mainPage__sidebar, pageDataStore.isSidebarOpen ? styles.mainPage__sidebar_open : styles.mainPage__sidebar_hide)}
                        markup={pageDataStore.markup.sidebar}
                    />
                ) : null }
            <div className={cn(styles.mainPage__main, pageDataStore?.isSidebarOpen ? styles.mainPage__main_hidden : styles.mainPage__main_active)}>
                {pageDataStore?.hasHeader ? <Header markup={pageDataStore.markup.header}/> : null}
                {__renderBlocks()}
                {pageDataStore?.hasFooter ? <Footer markup={pageDataStore.markup.footer}/> : null}
            </div>
        </div>
    )
}

export default observer(MainPage);