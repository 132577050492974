import cn from "classnames"
import styles from "./styles.module.scss"
import React from "react"

type Props = {
    className?: string
    title: string
    subtitle?: string
}

export const AsideTitle: React.FC<Props> = (props) => {
    return (
        <div className={cn(props.className, styles.asideTitle)}>
            <h2 className={cn(styles.asideTitle__subtitle, !!!props.subtitle ? styles.asideTitle__subtitle_hide : {})}>{props.subtitle}</h2>
            <div/>
            <h1 className={styles.asideTitle__title}>{props.title}</h1>
        </div>
    )
}