import React from 'react';
import styles from './HyperLink.module.scss';
import cn from 'classnames';

export type HyperLinkProps = {
    className?: string;
    href?: string;
    text?: string;
}

export const HyperLink: React.FC<HyperLinkProps> = ({
    className,
    href,
    text
}, ...args) => {
    return (
        <a
            className={cn(className, styles.hyperLink)}
            href={href}
            {...args}
        >
            {text}
        </a>
    )
}