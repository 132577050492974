import React from "react";
import styles from './styles.module.scss';
import cn from 'classnames';

const Span: React.FC<SpanProps> = ({
    className,
    value,
    bold,
    underlined
}) => {
    return (
        <span className={cn(className, styles.span)}>
            {value}
        </span>
    )
}

export default Span;