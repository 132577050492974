import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Description, Title } from '../../../shared';
import { FAQCard } from '../../../entities';

const FAQContainer: React.FC<FAQContainerProps> = ({
    className,
    titleProps,
    fAQCardsProps
}) => {
    return (
        <div className={cn(className, styles.fAQContainer)}>
            <Title
                className={styles.fAQContainer__title}
                {...titleProps}
            />
            <div className={styles.fAQContainer__main}>
                <ul>
                    {fAQCardsProps?.map((fAQCardProps, index) => (
                        <li key={index}>
                            <FAQCard
                                className={styles.fAQContainer__card}
                                {...fAQCardProps}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default FAQContainer