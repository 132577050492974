import React, { useState } from 'react';
import styles from './ArticleElementsPicker.module.scss';
import cn from 'classnames';
import { ArticleElement, ArticleElementProps } from '../../../entities';

export type ArticleElementsPickerProps = {
    className?: string;
    items: ArticleElementProps[];
    selectedIndexes: any[],
    onChange?: (e: any) => void
}

export const ArticleElementsPicker: React.FC<ArticleElementsPickerProps> = ({
    className,
    items,
    selectedIndexes,
    onChange
}, ...args) => {

    const __onItemClick = (key: any) => {
        if (onChange) onChange(key);
    }

    // console.log(selectedIndexes)

    return (
        <ul
            className={cn(className, styles.articleElementsPicker)}
            {...args}
        >
            {items.map((it, index) => (
                <ArticleElement
                    className={styles.articleElementsPicker__item}
                    selected={selectedIndexes.includes(it.key)}
                    key={it.key}
                    onClick={() => __onItemClick(it.key)}
                    {...it}
                />
            ))}
        </ul>
    )
}