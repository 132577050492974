import React from 'react';
import styles from './PortionPicker.module.scss';
import cn from 'classnames';
import { PortionCard } from '../../../entities';

export type PortionPickerProps = {
    className?: string;
    portionsCardsProps: any[];
    selectedIndex: number;
    onChange: (key: any) => void;
}

export const PortionPicker: React.FC<PortionPickerProps> = ({
    className,
    portionsCardsProps,
    selectedIndex,
    onChange
}, ...args) => {

    const __onPortionCardChange = (key: any) => {
        onChange(key)
    }

    return (
        <div className={cn(className, styles.portionPicker)} {...args}>
            {portionsCardsProps.map((portionProps, index) => (
                <PortionCard
                    className={cn(styles.portionPicker__portionCard)}
                    isSelected={index === selectedIndex}
                    onClick={() => __onPortionCardChange(index)}
                    {...portionProps}
                />
            ))}
        </div>
    )
}