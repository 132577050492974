import React from 'react';
import styles from './VerticalTabs.module.scss';
import cn from 'classnames';

type Props = {
    className?: string;
    selected: any;
    items: {
        key?: any,
        value?: any
    }[];
    onChange: (key: any) => void;
};

const VerticalTabs: React.FC<Props> = ({
    className,
    selected,
    items,
    onChange
}) => {

    const handleClick = (key: any) => {
        onChange(key)
    }

    return (
        <ul className={cn(className, styles.verticalTabs)}>
            {items.map(it => (
                <li
                    className={it.key === selected ? styles.listitem_v1 : styles.listitem_v2} key={it.key}
                    onClick={() => handleClick(it.key)}>
                    <span>{it.value}</span>
                </li>
            ))}
        </ul>
    )
};

export default VerticalTabs;