import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchCCS from "../../core/ccs/api/fetchCCS"

type Params = {
    collectionName: string
    id: number
}

export const fetchCollectionItem = createAsyncThunk("fetchCollectionItem", async (params: Params) => {
    return await fetchCCS(
        params.collectionName, 
        [
            `populate=deep`,
        ],
        [],
        params.id
    )
})