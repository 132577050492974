import React, { useState } from "react"
import styles from './styles.module.scss';
import cn from 'classnames';
import { Description, Span } from "../../../shared";

const FAQCard: React.FC<FAQCardProps> = ({
    className,
    keySpanProps,
    questionSpanProps,
    descriptionProps
}) => {

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <div className={cn(className, styles.fAQCard)}>
            <div
                className={styles.fAQCard__questionContainer}
                onClick={() => handleClick()}>
                    <Span
                        className={styles.fAQCard__key}
                        {...keySpanProps}
                    />
                    <Span
                        className={styles.fAQCard__question}
                        {...questionSpanProps}
                    />
                    {/* <LargePlusIcon/> */}
            </div>
            <div className={open ? styles.fAQCard__answerContainer_active : styles.fAQCard__answerContainer_hide}>
                <Description
                    className={styles.fAQCard__description}
                    {...descriptionProps}/>
            </div>
        </div>
    )
}

export default FAQCard;