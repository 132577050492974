import React, { useState } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Description, Image, MetaContainer, Tabs } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import LeadShortSection from '../../../features/lead/shortSection/ui/LeadShortSection';
import { ArticleCard2 } from '../../../entities';
import { ImagesSlider } from '../../../features';

const Main4Block: React.FC<Main4BlockProps> = ({
    className,
    markup
}) => {
    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <div className={cn(className, styles.main4Block)}>
            <div className={styles.main4Block__header}>
                <Image
                    className={styles.main4Block__baseImage}
                    src={parseCCSImage(markup?.$header?.$image?.value)}
                />
                <div className={styles.main4Block__textWrapper}>
                    <h1 className={styles.main4Block__title}>{markup?.$header?.$title?.value}</h1>    
                    <Description
                        className={styles.main4Block__description}
                        text={markup?.$header?.$description?.value}
                    />
                </div>
            </div>
            <LeadShortSection
                className={styles.main4Block__leadShortSection}
                title={markup?.$shortLeadSection?.$title?.value}
                inputLine1PlaceHolder={markup?.$shortLeadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$shortLeadSection?.$input2?.$placeholder?.value}
                confirmButtonLabel={markup?.$shortLeadSection?.$confirmButton?.$label?.value}
                pdfPath={parseCCSImage(markup?.$shortLeadSection?.$fileHyperLink?.$file?.value)}
                pdfLabel={markup?.$shortLeadSection?.$fileHyperLink?.$text?.value}
            />
            <Description
                className={styles.main4Block__description2}
                text={markup?.$description?.value}
            />
            <Tabs
                className={styles.main4Block__tabs}
                selected={selectedTab}
                onChange={(e) => setSelectedTab(e)}
                items={markup?.$tabs?.$tabItemsList.items?.map((tab, index) => {
                    return {
                        label: tab.$label?.value,
                        key: index
                    }
                }) || []}
            />
            <div className={styles.main4Block__main}>
                {markup?.$articleCard2sList?.items?.at(selectedTab)?.items?.map(articleCard2 => (
                    <ArticleCard2
                        imageProps={{
                            src: parseCCSImage(articleCard2.$image?.value),
                            blurhash: articleCard2.$image?.value?.data.attributes.blurhash
                        }}
                        nameLabelProps={{
                            value: articleCard2.$nameLabel?.value
                        }}
                        descriptionProps={{
                            text: articleCard2.$description?.value
                        }}
                    />
                ))}
            </div>
            <MetaContainer
                className={styles.main4Block__metaContainer}
                asideTitleProps={{
                    value: markup?.$metaContainer?.$asideMeta?.$title?.value
                }}
                metaCardsProps={markup?.$metaContainer?.$metaCardsList?.items?.map(metaCard => {
                    return {
                        titleProps: {
                            value: metaCard.$title?.value
                        },
                        iconProps: {
                            iconUID: metaCard.$icon?.value
                        }

                    }
                }) || []}
            />
            <ImagesSlider
                className={styles.main4Block__imagesSlider}
                imagesProps={markup?.$imagesSlider?.$imagesList?.items?.map(image => {
                    return {
                        src: parseCCSImage(image.value),
                        blurhash: image.value?.data.attributes.blurhash
                    }
                }) || []}
            />
        </div>
    )
}

export default Main4Block;