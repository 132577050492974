import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Tabs } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import BoardItem from '../../../features/boarditem/ui/BoardItem';
import { LeadSection } from '../../../features';

const Main2Block: React.FC<Main2BlockProps> = ({
    markup
}) => {

    const [selectedTab, setSelectedTab] = useState<number>(0)
    
    return (
        <div className={styles.main2Block}>
            <LeadSection
                className={styles.main2Block__leadSection}
                title={markup?.$leadSection?.$title?.value}
                description={markup?.$leadSection?.$description?.value}
                asideTitleSectionTitle={markup?.$leadSection?.$asideMeta?.$title?.value}
                asideTitleSectionSubtitle={markup?.$leadSection?.$asideMeta?.$subtitle?.value}
                baseImageSrc={parseCCSImage(markup?.$leadSection?.$image?.value)}
                confirmButtonLabel={markup?.$leadSection?.$confirmButton?.$label?.value}
                inputLine1PlaceHolder={markup?.$leadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$leadSection?.$input2?.$placeholder?.value}
                pdfLabel={markup?.$leadSection?.$fileHyperLink?.$text?.value}
                pdfPath={parseCCSImage(markup?.$leadSection?.$fileHyperLink?.$file?.value)}
            />
            <div className={styles.main2Block__header}>
                <h1 className={styles.title_v1}>{markup?.$tabs?.$title?.value}</h1>                    
            </div>
            <Tabs
                className={styles.main2Block__navTabs}
                selected={selectedTab}
                onChange={(e) => setSelectedTab(e)}
                items={(markup?.$tabs?.$tabItemsList.items && markup?.$tabs?.$tabItemsList.items?.map((it, index) => {
                    return {
                        key: index,
                        label: it.$label?.value,
                    }
                })) || []}
                
            />
            <div className={styles.main2Block__boardSection}>
                {markup?.$gridCardsList?.items?.at(selectedTab)?.items?.map(it => (
                    <BoardItem
                        imageProps={{
                            src: parseCCSImage(it.$image?.value),
                            blurhash: it.$image?.value?.data.attributes.blurhash
                        }}
                        label={it.$title?.value || ''}
                        path={it.$link?.value || ''}
                    />
                ))}
            </div>
        </div>
    )
}

export default Main2Block;