import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Title } from '../title';
import { Description, DescriptionProps } from '../description';
import Icon from '../Icon';

export const MetaCard: React.FC<MetaCardProps> = ({
    className,
    iconProps,
    titleProps,
    descriptionProps
}, ...args) => {
    return (
        <div className={cn(className, styles.metaCard)} {...args}>
            <div className={styles.metaCard__heading}>
                <Icon
                    className={styles.metaCard__icon}
                    {...iconProps}
                />
                <Title
                    className={styles.metaCard__title}
                    {...titleProps}
                />
            </div>
            <Description
                className={styles.metaCard__description}
                {...descriptionProps}
            />
        </div>
    )
}