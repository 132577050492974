import { useEffect, useState } from "react"
import { useAppStore } from "../../../shared"
import { RouterProvider } from "react-router-dom"
import { createRouter } from "../lib/create-router"
import CCREST from "../../../shared/api/CCREST"

export const Routing = () => {
    const [routes, setRoutes] = useState<CCRoute[]>([])
    const [router, setRouter] = useState<any>()
    const { appStore } = useAppStore()


    useEffect( () => {
        if (!!appStore) {
            appStore.updateRoutes().then(async () => {
                return  createRouter(appStore.routes)
            }).then((result) => {
                setRouter(result)
            })
        }
    }, [])

    return appStore && appStore.routes.length !== 0 ? (
        <RouterProvider
            router={router}
        />
    ) : appStore?.isLoading ? (
        <>pending</>
    ) : (
        <>error</>
    )
}