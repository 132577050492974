import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Description, Icon, Image, Span, Title } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';

const CrewMembersSlider: React.FC<CrewMembersSliderProps> = ({
    className,
    titleProps,
    crewMembersCards,
    leftButtonProps,
    rightButtonProps
}) => {

    const [selectedItem, setSelectedItem] = useState(0)
    const [disableClick, setDisableClick] = useState(false)

    const handleLeftClick = () => {
        setDisableClick(true)
        setSelectedItem((selectedItem - 1) % crewMembersCards.length)
        setTimeout(() => {
            setDisableClick(false)
        }, 1000)
    }

    const handleRightClick = () => {
        setDisableClick(true)
        setSelectedItem((selectedItem + 1) % crewMembersCards.length)
        setTimeout(() => {
            setDisableClick(false)
        }, 1000)
    }

    return (
        <div className={cn(className, styles.crewMembersSlider)}>
            <div className={styles.crewMembersSlider__itemsContainer}>
                <div className={styles.crewMembersSlider__header}>
                    <Title {...titleProps}/>
                    <div/>
                </div>
                <div className={styles.crewMembersSlider__itemsSection}>
                    <div>
                        <Image
                            className={styles.crewMembersSlider__image}
                            {...crewMembersCards?.at(selectedItem - 2)?.imageProps}
                        />
                    </div>
                    <div>
                        <Image
                            className={styles.crewMembersSlider__image}
                            {...crewMembersCards?.at(selectedItem - 1)?.imageProps}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.crewMembersSlider__infoContainer}>
                <div>
                    <Image
                        className={styles.crewMembersSlider__baseImage}
                        {...crewMembersCards.at(selectedItem)?.imageProps}
                    />
                </div>
                <div className={styles.crewMembersSlider__infoSection}>
                    <Span
                        className={styles.crewMembersSlider__name}
                        {...crewMembersCards?.at(selectedItem)?.nameSpanProps}
                    />
                    <Span
                        className={styles.crewMembersSlider__jobTitle}
                        {...crewMembersCards?.at(selectedItem)?.jobSpanProps}
                    />
                    <Description
                        className={styles.crewMembersSlider__description}
                        {...crewMembersCards?.at(selectedItem)?.descriptionProps}/>
                    <div className={styles.crewMembersSlider__controller}>
                        <button
                            disabled={disableClick}
                            onClick={handleLeftClick}>
                                <Icon
                                    iconUID={leftButtonProps?.iconUID}
                                />
                        </button>
                        <button
                            disabled={disableClick}
                            onClick={handleRightClick}>
                                <Icon
                                    iconUID={rightButtonProps?.iconUID}
                                />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrewMembersSlider;