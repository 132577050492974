import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Button } from '../../../shared';
import { Description, Image } from '../../../shared';
import { parseCCSImage } from '../../../core/ccs/utils/parseCCSImages';
import MenusSection from '../../../features/menussection/ui/MenusSection';
import { ImagesSlider } from '../../../shared/ui/imagesslider/ImagesSlider';
import { LeadSection } from '../../../features';

const Main3Block: React.FC<Main3BlockProps> = ({
    markup
}) => {
    return (
        <div className={styles.main3Block}>
            <LeadSection
                className={styles.main3Block__leadSecion}
                title={markup?.$leadSection?.$title?.value}
                description={markup?.$leadSection?.$description?.value}
                asideTitleSectionTitle={markup?.$leadSection?.$asideMeta?.$title?.value}
                asideTitleSectionSubtitle={markup?.$leadSection?.$asideMeta?.$subtitle?.value}
                baseImageSrc={parseCCSImage(markup?.$leadSection?.$image?.value)}
                confirmButtonLabel={markup?.$leadSection?.$confirmButton?.$label?.value}
                inputLine1PlaceHolder={markup?.$leadSection?.$input1?.$placeholder?.value}
                inputLine2PlaceHolder={markup?.$leadSection?.$input2?.$placeholder?.value}
                pdfLabel={markup?.$leadSection?.$fileHyperLink?.$text?.value}
                pdfPath={parseCCSImage(markup?.$leadSection?.$fileHyperLink?.$file?.value)}
            />
            <div className={styles.main3Block__header}>
                <div className={styles.main3Block__titleSection}>
                    <h1 className={cn(styles.main3Block__title, styles.title_v1)}>{markup?.$title?.value}</h1>
                    <div/>
                </div>
                <Button
                    className={cn(styles.main3Block__universalOffersButton, styles.button_v3)}
                    label={markup?.$offersButton?.$label?.value}
                    iconUID={markup?.$offersButton?.$icon?.value}
                    onClick={() => {}}
                />
            </div>
            <div className={styles.main3Block__main}>
                <Description
                    className={cn(styles.main3Block__description, styles.description_v1)}
                    text={markup?.$description?.value}
                />
                <Image
                    className={styles.main3Block__baseImage}
                    src={parseCCSImage(markup?.$image?.value)}
                />
            </div>
            <MenusSection
                className={styles.main3Block__menusSection}
                items={markup?.$menuPicker?.$menusList?.items?.map((menu, index) => {
                    return {
                        id: index,
                        attributes: {
                            description: menu.$description?.value,
                            warning: menu.$alertSpan?.value,
                            file: parseCCSImage(menu.$file?.value)
                        }
                    }
                })}
            />
            <ImagesSlider
                className={styles.main3Block__imagesSlider}
                srcs={markup?.$imagesSlider?.$imagesList?.items?.map((image) => String(parseCCSImage(image.value))) || []}
            />
        </div>
    )
}

export default Main3Block;