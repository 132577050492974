import CCSMediaData from "../lib/CCSMediaDto";
import CCSResponse from "../lib/CCSResponse";
import generateCCSImageSrc from "./generateCCSImageSrc";

export const parseCCSImagesList = (
    images?: any
) => {
    if (!!images?.data && Array.isArray(images.data)) {
        return (images.data as any[]).map(it => generateCCSImageSrc(it.attributes.url))
    }
};

export const parseCCSImage = (
    images?: any | any[]
) => {
    if (Array.isArray(images)) {
        return !!images?.at(0)?.value?.data?.attributes?.url ? generateCCSImageSrc(images?.at(0)?.value?.data?.attributes?.url!) : undefined
    }
    return !!images?.data?.attributes?.url ? generateCCSImageSrc(images?.data?.attributes?.url) : undefined
};