import CCSRequestFilter from '../lib/CCSRequestFilter';
import generateCCSFiltersParams from './generateCCSFiltersParams';

const generateCCSRequestUrl = (
    path: string,
    params: string[],
    filters: CCSRequestFilter[],
    id?: number
) => {
    let parsedFilters: string[] = generateCCSFiltersParams(filters)
    return `${process.env.REACT_APP_CCS_API_URL}${process.env.REACT_APP_CCS_API_PATH}` + '/' + path + (!!id ? `/${id}` : '') + '?' + params.concat(parsedFilters).join('&') 
}

export default generateCCSRequestUrl