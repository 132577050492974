import { useLoaderData } from "react-router-dom"
import React from "react"
import { PageDataStoreProvider } from "../shared/lib/usePageDataStore"
import MainPage from "./main/ui"

const PageControl: React.FC = () => {
    const { dataStore } = useLoaderData() as any
    
    if (!dataStore?.markup) return <>Ошибка Компонента</>
    
    return (
        <PageDataStoreProvider pageDataStore={dataStore}>
            <MainPage/>
        </PageDataStoreProvider>
    )
}

export default PageControl;