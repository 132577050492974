import { useEffect, useState } from 'react';
import './App.css';
import { fetchPages } from '../../shared';
import { Routing } from '../../processes/routing';


export const App = () => {  
  return (
    <Routing/>
  );
}
